import React from 'react'
import { Button } from '../../Button/Button'
import { NotificationsBell } from '../../Icons/NotificationsBell/NotificationsBell'
import Scroller from '../../Scroller/Scroller'
import {
  NotificationsState,
  NotificationType
} from '../../../metrics_server/notifications/types'
import { NotificationsListItem } from './NotificationsListItem/NotificationsListItem'
import { useComponentVisible } from './useComponentVisible'
import { css } from '@emotion/css'
import styles from './NotificationsList.module.scss'
import { sportableColors } from '../../../constants/sportableColors'

export interface NotificationsListProps {
  notifications: NotificationsState
  numberOfAlerts: number
  clearSelectedNotification: (notification: NotificationType) => void
  clearAllNotifications: () => void
}

export const NotificationsList = (props: NotificationsListProps) => {
  const { notifications, numberOfAlerts } = props

  const { ref, isComponentVisible, clickedOutside, setIsComponentVisible } =
    useComponentVisible(false)

  const toggleExpand = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  const handleClearNotification = (notification: NotificationType) => {
    return props.clearSelectedNotification(notification)
  }

  const getScrollHeight = (numOfAlerts: number) => {
    return numOfAlerts === 1
      ? '85px'
      : numOfAlerts === 2
      ? '165px'
      : numOfAlerts === 3
      ? '245px'
      : numOfAlerts >= 4
      ? '325px'
      : undefined
  }

  const badgeStyle = (count: number) => css`
    ::after {
      content: '${count}';
      background: ${sportableColors.colors.sportableBlue};
      color: white;
      position: absolute;
      right: 0;
      top: 0px;
      width: ${count < 10 ? '16px' : '18px'};
      height: ${count < 10 ? '16px' : '18px'};
      border-radius: 100%;
      font-size: ${count < 10 ? '10px' : '12px'};
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    }
  `

  return (
    <div
      onClick={!isComponentVisible || clickedOutside ? toggleExpand : undefined}
      className={styles.notificationContainer}
      ref={ref}
    >
      <NotificationsBell
        isVisible={isComponentVisible}
        style={{ marginTop: '5px' }}
        scale={1.2}
      />
      {numberOfAlerts > 0 && (
        <span className={styles.notificationBadge}>
          <span className={badgeStyle(numberOfAlerts)}></span>
        </span>
      )}
      {isComponentVisible && (
        <div className={styles.notificationDropdown}>
          <div className={styles.notificationsHeader}>
            {numberOfAlerts >= 1 ? (
              <>
                <h5>
                  {numberOfAlerts} {numberOfAlerts > 1 ? 'Alerts' : 'Alert'}
                </h5>

                <Button
                  id={'clearAll'}
                  disabled={false}
                  className={'btn--thin'}
                  handleClick={props.clearAllNotifications}
                >
                  CLEAR ALERTS
                </Button>
              </>
            ) : (
              <h5 style={{ padding: '5px' }}>No Alerts</h5>
            )}
          </div>
          <div
            style={{
              height: getScrollHeight(numberOfAlerts)
            }}
          >
            {numberOfAlerts >= 1 ? (
              <Scroller
                scrollerId='scroller'
                scrollerClass='notifications-scroller'
              >
                <div className={styles.notificationScroller}>
                  {notifications.allNotificationsHistory
                    .slice(0)
                    .reverse()
                    .map((notification, index) => {
                      return (
                        <NotificationsListItem
                          key={`${notification?.timestamp ?? ''}-${index}`}
                          notification={notification}
                          handleClearNotification={handleClearNotification}
                        />
                      )
                    })}
                </div>
              </Scroller>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
