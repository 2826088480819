import React, { useState } from 'react'
import { FormattedTeamSession } from '../../../metrics_server/sessions/types'
import { Button } from '../../Button/Button'
import Checkbox from '../Inputs/Checkbox/Checkbox'
import TextInput from '../Inputs/TextInput/TextInput'
import styles from './styles.module.scss'
import { Group } from '../../../metrics_server/types'
import {
  SessionDownloadType,
  useFormattedSession
} from '../../../metrics_server/sessions/hooks'
import BasicSelect from '../../Material/Select'
import { majorTimezones } from '../../../utils/moment'
import { isAmFooty } from '../../../metrics_server/sports/functions'
import { isLocal } from '../../../metrics_server/env'

export interface DownloadFormState {
  isAdmin: boolean
  teams: Group<FormattedTeamSession>
  handleProceed: (
    type: string,
    offset: number,
    team: string,
    timezone?: string,
    drills?: boolean
  ) => void
  handleClose: () => void
  sessionId: string
}

const DownloadForm = ({
  isAdmin = false,
  teams,
  handleProceed,
  handleClose,
  sessionId
}: DownloadFormState): JSX.Element => {
  const { sport, isTrainingMode } = useFormattedSession(sessionId)

  const [type, setType] = useState<SessionDownloadType>('csv')
  const [xmlTeam, setXMLTeam] = useState<string>('')
  const [csvTeam, setCSVTeam] = useState<string>('')
  const [offset, setOffset] = useState<number>(0)
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const [selectedDownloads, setSelectedDownloads] = useState({
    csv: {
      drills: false
    },
    xml: {
      drills: false
    }
  })

  const handleCheckboxChange = (_: boolean, type: SessionDownloadType) => {
    setType(type)
  }

  const handleXMLTeamCheckboxChange = (value, name: string) => {
    let team = name
    if (!value) {
      team = ''
    }
    setXMLTeam(team)
  }

  const handleCSVTeamCheckboxChange = (value, name: string) => {
    let team = name
    if (!value) {
      team = ''
    }
    setCSVTeam(team)
  }
  let team1: FormattedTeamSession | undefined
  let team2: FormattedTeamSession | undefined
  if (teams?.list?.length === 2) {
    team1 = teams?.list[0]
    team2 = teams?.list[1]
  }

  const timeZoneOptions = majorTimezones.map((timezone) => ({
    name: timezone,
    value: timezone
  }))

  const TimezoneSelect = ({
    selectedTimezone,
    timeZoneOptions,
    setSelectedTimezone
  }: {
    selectedTimezone: string
    timeZoneOptions: { name: string; value: string }[]
    setSelectedTimezone: (value: string) => void
  }) => {
    if (!isAmFooty(sport)) {
      return null
    }

    return (
      <BasicSelect
        label={'Timezone'}
        selected={selectedTimezone}
        options={timeZoneOptions}
        size={'small'}
        variant={'standard'}
        onChange={setSelectedTimezone}
        width={'40%'}
      />
    )
  }

  return (
    <div>
      <h4 style={{ marginBottom: '30px' }}>Select file type to download:</h4>
      <div>
        <div className={styles.allSessionEventsContainer}>
          <Checkbox
            onClicked={(value) => handleCheckboxChange(value, 'csv')}
            label={`CSV Format`}
            checked={type === 'csv'}
          />
          {type === 'csv' && (
            <>
              {isTrainingMode && (
                <div className={styles.indent}>
                  <Checkbox
                    onClicked={(value) =>
                      setSelectedDownloads((prev) => ({
                        ...prev,
                        csv: {
                          drills: value
                        }
                      }))
                    }
                    label='Drill events'
                    checked={selectedDownloads.csv.drills}
                  />
                </div>
              )}
              <div className={styles.offsetInput}>
                <div style={{ fontSize: '13px' }}>Offset (s):</div>
                <div className='smallSelectContainer'>
                  <TextInput
                    disabled={type !== 'csv'}
                    small={true}
                    type='number'
                    input={{
                      onChange: (e) => {
                        const num = Number(e.target.value)
                        setOffset(num)
                      },
                      name: 'offset',
                      value: offset
                    }}
                  />
                </div>
              </div>
              <TimezoneSelect
                selectedTimezone={selectedTimezone}
                timeZoneOptions={timeZoneOptions}
                setSelectedTimezone={setSelectedTimezone}
              />
            </>
          )}
        </div>
        {team1 && team2 && (
          <div className={styles.indent}>
            <Checkbox
              onClicked={(value) => handleCSVTeamCheckboxChange(value, '')}
              label={`Both teams as single file`}
              checked={csvTeam === ''}
              disabled={type !== 'csv'}
            />
            <Checkbox
              onClicked={(value) => handleCSVTeamCheckboxChange(value, 'both')}
              label={`Both teams as separate files`}
              checked={csvTeam === 'both'}
              disabled={type !== 'csv'}
            />
            <Checkbox
              onClicked={(value) =>
                handleCSVTeamCheckboxChange(value, team1.teamId)
              }
              label={`${team1.team.name}`}
              checked={csvTeam === team1.teamId}
              disabled={type !== 'csv'}
            />
            {team2 && (
              <Checkbox
                onClicked={(value) =>
                  handleCSVTeamCheckboxChange(value, team2.teamId)
                }
                label={`${team2.team.name}`}
                checked={csvTeam === team2.teamId}
                disabled={type !== 'csv'}
              />
            )}
          </div>
        )}

        <div className={styles.allSessionEventsContainer}>
          <Checkbox
            onClicked={(value) => handleCheckboxChange(value, 'xml')}
            label={`XML Format`}
            checked={type === 'xml'}
          />
          {type === 'xml' && (
            <>
              {isTrainingMode && (
                <div className={styles.indent}>
                  <Checkbox
                    onClicked={(value) =>
                      setSelectedDownloads((prev) => ({
                        ...prev,
                        xml: {
                          drills: value
                        }
                      }))
                    }
                    label='Drill events'
                    checked={selectedDownloads.xml.drills}
                  />
                </div>
              )}
              <div className={styles.offsetInput}>
                <div style={{ fontSize: '13px' }}>Offset (s):</div>
                <div className='smallSelectContainer'>
                  <TextInput
                    disabled={type !== 'xml'}
                    small={true}
                    type='number'
                    input={{
                      onChange: (e) => {
                        const num = Number(e.target.value)
                        setOffset(num)
                      },
                      name: 'offset',
                      value: offset
                    }}
                  />
                </div>
              </div>
              <TimezoneSelect
                selectedTimezone={selectedTimezone}
                timeZoneOptions={timeZoneOptions}
                setSelectedTimezone={setSelectedTimezone}
              />
            </>
          )}
        </div>

        {team1 && team2 && (
          <div className={styles.indent}>
            <Checkbox
              onClicked={(value) => handleXMLTeamCheckboxChange(value, '')}
              label={`Both teams as single file`}
              checked={xmlTeam === ''}
              disabled={type !== 'xml'}
            />
            <Checkbox
              onClicked={(value) => handleXMLTeamCheckboxChange(value, 'both')}
              label={`Both teams as separate files`}
              checked={xmlTeam === 'both'}
              disabled={type !== 'xml'}
            />
            <Checkbox
              onClicked={(value) =>
                handleXMLTeamCheckboxChange(value, team1.teamId)
              }
              label={`${team1.team.name}`}
              checked={xmlTeam === team1.teamId}
              disabled={type !== 'xml'}
            />
            {team2 && (
              <Checkbox
                onClicked={(value) =>
                  handleXMLTeamCheckboxChange(value, team2.teamId)
                }
                label={`${team2.team.name}`}
                checked={xmlTeam === team2.teamId}
                disabled={type !== 'xml'}
              />
            )}
          </div>
        )}
        {!isLocal && (
          <>
            <Checkbox
              onClicked={(value) =>
                handleCheckboxChange(value, 'ball_position')
              }
              label={`Ball Position data`}
              checked={type === 'ball_position'}
            />
            <Checkbox
              onClicked={(value) =>
                handleCheckboxChange(value, 'player_position')
              }
              label={`Player Position data`}
              checked={type === 'player_position'}
            />
            <Checkbox
              onClicked={(value) =>
                handleCheckboxChange(value, 'player_position_per_event')
              }
              label={`Tactical Player Position data`}
              checked={type === 'player_position_per_event'}
            />
            <Checkbox
              onClicked={(value) =>
                handleCheckboxChange(value, 'player_breakdown')
              }
              label={`Player Breakdown`}
              checked={type === 'player_breakdown'}
            />
            <Checkbox
              onClicked={(value) =>
                handleCheckboxChange(value, 'session_players')
              }
              label={`Session Players`}
              checked={type === 'session_players'}
            />
          </>
        )}
        {isAdmin && !isLocal && (
          <Checkbox
            onClicked={(value) => handleCheckboxChange(value, 'raw_session')}
            label={`Raw Session Data`}
            checked={type === 'raw_session'}
          />
        )}
        {isAdmin && (
          <Checkbox
            onClicked={(value) => handleCheckboxChange(value, 'pitch')}
            label={`Pitch & anchor data (.json)`}
            checked={type === 'pitch'}
          />
        )}
      </div>
      <div className={styles.buttonsContainer}>
        <div style={{ margin: '15px' }}>
          <Button
            type='submit'
            className='btn--primary'
            handleClick={() =>
              handleProceed(
                type,
                offset,
                type === 'csv' ? csvTeam : xmlTeam,
                selectedTimezone,
                selectedDownloads[type]?.drills
              )
            }
          >
            Proceed
          </Button>
        </div>
        <div style={{ margin: '15px' }}>
          <Button type='submit' handleClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DownloadForm
