// Used in validation and to change the 'from' player of a flight
import React, { useCallback } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import { SelectForm } from '../../../components/Forms/SelectForm/SelectForm'
import { updateEvent } from '../../../metrics_server/events/actions'
import { useSelectedFormattedSession } from '../../../metrics_server/sessions/hooks'
import { toggleModal } from '../../../ui/modal/actions'
import { useSelectedFormattedEvent } from '../../../metrics_server/events/hooks'
import { eventTypes } from '../../../metrics_server/events/data_types'

export const useChangeFromPlayerModal = () => {
  const dispatch = useAppDispatch()
  const formattedEvent = useSelectedFormattedEvent()
  const { players } = useSelectedFormattedSession()

  const openChangeFromPlayerModal = useCallback(() => {
    if (!formattedEvent) return
    const { id, player, eventType, team } = formattedEvent

    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        title: 'Select Player',
        className: 'modalLarge',
        wrapper: true,

        ChildComponent: () => (
          <SelectForm
            handleSubmit={(values) => {
              // TODO: do we need to set the teamId if we are filtering the player options by team
              // This is clunky - we should be able to just pass the player id to the updateEvent action
              const player = players.all.map[values.selected]
              let data = {
                id,
                fromPlayerId: null,
                teamID: null,
                playerId: null,
                teamId: null
              }
              // TODO: this logic should be handled in the backend
              if (
                formattedEvent &&
                eventType === eventTypes.items.flight.value
              ) {
                data = { ...data, teamID: team.selected?.id }
                data.fromPlayerId =
                  values.selected === 'none' ? null : values.selected
                if (player) data.teamID = player.teamId
              } else if (
                formattedEvent &&
                eventType === eventTypes.items.game.value
              ) {
                data = { ...data, teamID: team.selected?.id }
                data.playerId =
                  values.selected === 'none' ? null : values.selected
                if (player) {
                  delete data.teamID
                  data.teamId = player.teamId
                }
              }
              dispatch(updateEvent(data))
              dispatch(toggleModal({}))
            }}
            options={player.options}
            initialValue={player?.selected ? player.selected.id : 'none'}
            // title={'Select Player'}
          />
        ),
        handleSecondaryBtn: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [formattedEvent])

  return {
    openChangeFromPlayerModal
  }
}
