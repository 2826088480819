import { drawCircle, drawLine } from '../2D/drawing'
import { Dimensions, Field, Poles } from '../types'

export function applyRugbySettings(
  field: Field,
  poles: Poles,
  dimensions: Dimensions
) {
  field.height = dimensions.P21.y
  field.width = dimensions.P11.x - dimensions.P1.x
  field.tryLineDistance = dimensions.P10.x - dimensions.P2.x
  field.edges = 3

  field.planes = {
    left: dimensions.P1.x,
    right: dimensions.P11.x,
    top: dimensions.P21.y,
    bottom: dimensions.P6.y
  }

  // Set origin to base
  field.originOffsetX = 0
  field.originOffsetY = field.height / 2

  poles.height = dimensions.P32.z
  poles.width = dimensions.P33.y - dimensions.P31.y
  poles.crossbarHeight = dimensions.P31.z
  poles.diameter = 0.2

  field.color = '#089b64'

  for (let l = 1; l <= 10; l++) {
    field[`l${l}`] = dimensions[`P${l + 1}`].x - dimensions[`P${l}`].x
  }

  field.goalLineWidth = dimensions.P10.x - dimensions.P2.x
}

// Draw rugby pitch background on 2D canvas
export const drawRugbyLines = (
  props,
  color,
  ctx,
  pixelScale,
  view = '2D',
  pitchFlipped?
) => {
  const { field, dimensions, canvasElementPixelRatio } = props

  let getCanvasCoordinate = props.getCanvasCoordinate

  if (view === '3D') {
    getCanvasCoordinate = props.get3DCanvasCoordinate
  }

  field.dashSizeY = field.height / (14 * (field.height / 70))
  field.dashSizeX =
    (field.tryLineDistance / (20 * (field.tryLineDistance / 100))) *
    canvasElementPixelRatio

  // Draw mown patches

  let numberOfVerticalPatches = 20
  let numberOfHorizontalPatches = numberOfVerticalPatches * 0.7

  let verticalPatchWidth = field.tryLineDistance / numberOfVerticalPatches
  let horizontalPatchWidth = field.height / numberOfHorizontalPatches

  let patchOrigin = dimensions.P25
  if (pitchFlipped) {
    patchOrigin = dimensions.P2
  }

  for (let i = 0; i < numberOfVerticalPatches; i++) {
    if (i % 2 === 0) {
      let scaledOrigin = getCanvasCoordinate(
        pixelScale,
        patchOrigin.x + i * verticalPatchWidth,
        patchOrigin.y,
        true
      )
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX,
        scaledOrigin.scaleY,
        verticalPatchWidth * pixelScale,
        field.height * pixelScale
      )
    }
  }

  for (let i = 0; i < numberOfHorizontalPatches; i++) {
    if (i % 2 === 0) {
      let scaledOrigin = getCanvasCoordinate(
        pixelScale,
        patchOrigin.x,
        pitchFlipped
          ? patchOrigin.y + i * horizontalPatchWidth
          : patchOrigin.y - i * horizontalPatchWidth,
        true
      )
      ctx.fillStyle = 'rgba(0,0,0,0.1)'
      ctx.fillRect(
        scaledOrigin.scaleX,
        scaledOrigin.scaleY,
        field.tryLineDistance * pixelScale,
        horizontalPatchWidth * pixelScale
      )
    }
  }

  // Border

  ctx.beginPath()
  ctx.setLineDash([0])
  ctx.moveTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P26.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P26.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P16.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P16.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P11.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P11.y).scaleY
  )
  ctx.lineTo(
    getCanvasCoordinate(pixelScale, dimensions.P1.x).scaleX,
    getCanvasCoordinate(pixelScale, null, dimensions.P1.y).scaleY
  )
  ctx.lineWidth = 3
  ctx.strokeStyle = color
  ctx.stroke()

  // drawLine(0, 0, -(fieldWidth / 2), 0, ctx);
  // drawLine(0, 0, fieldWidth / 2, 0, ctx);
  // drawLine(0, fieldHeight, -(fieldWidth / 2), fieldHeight, ctx);
  // drawLine(0, fieldHeight, fieldWidth / 2, fieldHeight, ctx);

  // Halfway line
  drawLine(
    dimensions.P6.x,
    dimensions.P6.y,
    dimensions.P21.x,
    dimensions.P21.y,
    ctx,
    null,
    null,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  // Trylines
  drawLine(
    dimensions.P2.x,
    dimensions.P2.y,
    dimensions.P25.x,
    dimensions.P25.y,
    ctx,
    null,
    null,
    color,
    getCanvasCoordinate,
    pixelScale
  )
  drawLine(
    dimensions.P10.x,
    dimensions.P10.y,
    dimensions.P17.x,
    dimensions.P17.y,
    ctx,
    null,
    null,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  // 22

  drawLine(
    dimensions.P4.x,
    dimensions.P4.y,
    dimensions.P23.x,
    dimensions.P23.y,
    ctx,
    null,
    null,
    color,
    getCanvasCoordinate,
    pixelScale
  )
  drawLine(
    dimensions.P8.x,
    dimensions.P8.y,
    dimensions.P19.x,
    dimensions.P19.y,
    ctx,
    null,
    null,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  // 10
  drawLine(
    dimensions.P5.x,
    dimensions.P5.y,
    dimensions.P22.x,
    dimensions.P22.y,
    ctx,
    [2, 2],
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )
  drawLine(
    dimensions.P7.x,
    dimensions.P7.y,
    dimensions.P20.x,
    dimensions.P20.y,
    ctx,
    [2, 2],
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  const { dashSizeX, dashSizeY } = field

  // Check if there are more than 34 points, i.e. if the old coordinates are used
  let oldCords = Object.keys(dimensions).length > 34 ? true : false

  // 5

  oldCords ? dimensions.P35.x : dimensions.P31.x,
    oldCords ? dimensions.P35.y : dimensions.P31.y,
    drawLine(
      dimensions.P3.x,
      dimensions.P30.y - dashSizeY / 2,
      dimensions.P3.x,
      dimensions.P30.y + dashSizeY / 2,
      ctx,
      null,
      2,
      color,
      getCanvasCoordinate,
      pixelScale
    )

  drawLine(
    dimensions.P3.x,
    dimensions.P29.y - dashSizeY / 2,
    dimensions.P3.x,
    dimensions.P29.y + dashSizeY / 2,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P3.x,
    dimensions.P28.y - dashSizeY / 2,
    dimensions.P3.x,
    dimensions.P28.y + dashSizeY / 2,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P3.x,
    dimensions.P27.y - dashSizeY / 2,
    dimensions.P3.x,
    dimensions.P27.y + dashSizeY / 2,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P3.x,
    dimensions.P31.y - dashSizeY / 4,
    dimensions.P3.x,
    dimensions.P31.y + dashSizeY / 4,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P3.x,
    (oldCords ? dimensions.P36.y : dimensions.P32.y) - dashSizeY / 4,
    dimensions.P3.x,
    (oldCords ? dimensions.P36.y : dimensions.P32.y) + dashSizeY / 4,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P9.x,
    dimensions.P30.y - dashSizeY / 2,
    dimensions.P9.x,
    dimensions.P30.y + dashSizeY / 2,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P9.x,
    dimensions.P29.y - dashSizeY / 2,
    dimensions.P9.x,
    dimensions.P29.y + dashSizeY / 2,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P9.x,
    dimensions.P28.y - dashSizeY / 2,
    dimensions.P9.x,
    dimensions.P28.y + dashSizeY / 2,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P9.x,
    dimensions.P27.y - dashSizeY / 2,
    dimensions.P9.x,
    dimensions.P27.y + dashSizeY / 2,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P9.x,
    dimensions.P33.y - dashSizeY / 4,
    dimensions.P9.x,
    dimensions.P33.y + dashSizeY / 4,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  drawLine(
    dimensions.P9.x,
    (oldCords ? dimensions.P37.y : dimensions.P34.y) - dashSizeY / 4,
    dimensions.P9.x,
    (oldCords ? dimensions.P37.y : dimensions.P34.y) + dashSizeY / 4,
    ctx,
    null,
    2,
    color,
    getCanvasCoordinate,
    pixelScale
  )

  // 15 and 5 sideline

  let yPoints = ['P27', 'P28', 'P29', 'P30']

  yPoints.forEach((yPoint) => {
    drawLine(
      dimensions.P24.x,
      dimensions[yPoint].y,
      dimensions.P24.x + dashSizeX,
      dimensions[yPoint].y,
      ctx,
      null,
      2,
      color,
      getCanvasCoordinate,
      pixelScale
    )

    drawLine(
      dimensions.P23.x - dashSizeX / 3,
      dimensions[yPoint].y,
      dimensions.P23.x + dashSizeX / 3,
      dimensions[yPoint].y,
      ctx,
      null,
      2,
      color,
      getCanvasCoordinate,
      pixelScale
    )

    drawLine(
      dimensions.P22.x - dashSizeX / 3,
      dimensions[yPoint].y,
      dimensions.P22.x + dashSizeX / 3,
      dimensions[yPoint].y,
      ctx,
      null,
      2,
      color,
      getCanvasCoordinate,
      pixelScale
    )

    drawLine(
      dimensions.P21.x - dashSizeX / 3,
      dimensions[yPoint].y,
      dimensions.P21.x + dashSizeX / 3,
      dimensions[yPoint].y,
      ctx,
      null,
      2,
      color,
      getCanvasCoordinate,
      pixelScale
    )

    drawLine(
      dimensions.P20.x - dashSizeX / 3,
      dimensions[yPoint].y,
      dimensions.P20.x + dashSizeX / 3,
      dimensions[yPoint].y,
      ctx,
      null,
      2,
      color,
      getCanvasCoordinate,
      pixelScale
    )

    drawLine(
      dimensions.P19.x - dashSizeX / 3,
      dimensions[yPoint].y,
      dimensions.P19.x + dashSizeX / 3,
      dimensions[yPoint].y,
      ctx,
      null,
      2,
      color,
      getCanvasCoordinate,
      pixelScale
    )

    drawLine(
      dimensions.P18.x,
      dimensions[yPoint].y,
      dimensions.P18.x - dashSizeX,
      dimensions[yPoint].y,
      ctx,
      null,
      2,
      color,
      getCanvasCoordinate,
      pixelScale
    )
  })

  // Pole dots

  drawCircle(
    oldCords ? dimensions.P35.x : dimensions.P31.x,
    oldCords ? dimensions.P35.y : dimensions.P31.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P32.x,
    dimensions.P32.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    dimensions.P33.x,
    dimensions.P33.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
  drawCircle(
    oldCords ? dimensions.P37.x : dimensions.P34.x,
    oldCords ? dimensions.P37.y : dimensions.P34.y,
    ctx,
    3,
    color,
    'white',
    null,
    getCanvasCoordinate,
    pixelScale
  )
}
