import { SportTypeKey } from '../data_types'
import { sportTypeKey } from '../'

import {
  Coordinate,
  Pitch,
  PitchCoordinates,
  PitchDimensions
} from '../../pitches/types'
import { getPitchSetupTypeGroup } from '../../pitches/data_types'

export interface SoccerPitch extends Pitch {
  type: SportTypeKey['soccer']
  pitch: SoccerPitchDimensions
}

export interface SoccerPitchDimensions extends PitchDimensions {
  length: number
  width: number
  posts: {
    width: number
    height: number
    crossbarHeight: number
    diameter: number
  }
  coordinates: SoccerPitchCoordinates
}

export interface SoccerPitchCoordinates extends PitchCoordinates {
  P1: Coordinate
  P2: Coordinate
  P3: Coordinate
  P4: Coordinate
  P5: Coordinate
  P6: Coordinate
  P7: Coordinate
  P8: Coordinate
  P9: Coordinate
  P10: Coordinate
  P11: Coordinate
  P12: Coordinate
  P13: Coordinate
  P14: Coordinate
  P15: Coordinate
  P16: Coordinate
  P17: Coordinate
  P18: Coordinate
  P19: Coordinate
  P20: Coordinate
  P21: Coordinate
  P22: Coordinate
  P23: Coordinate
  P24: Coordinate
  P25: Coordinate
  P26: Coordinate
  P27: Coordinate
  P28: Coordinate
  P29: Coordinate
  P30: Coordinate
  P31: Coordinate
  P32: Coordinate
  P33: Coordinate
  P34: Coordinate
}

const coordinates = {
  P1: {
    x: -50.0,
    y: 0.0,
    z: 0.0
  },
  P2: {
    x: 0.0,
    y: 0.0,
    z: 0.0
  },
  P3: {
    x: 50.0,
    y: 0.0,
    z: 0.0
  },
  P4: {
    x: 50.0,
    y: 14.9,
    z: 0.0
  },
  P5: {
    x: 50.0,
    y: 25.85,
    z: 0.0
  },
  P6: {
    x: 50.0,
    y: 44.15,
    z: 0.0
  },
  P7: {
    x: 50.0,
    y: 55.1,
    z: 0.0
  },
  P8: {
    x: 50.0,
    y: 70.0,
    z: 0.0
  },
  P9: {
    x: 0.0,
    y: 70.0,
    z: 0.0
  },
  P10: {
    x: -50.0,
    y: 70.0,
    z: 0.0
  },
  P11: {
    x: -50.0,
    y: 55.1,
    z: 0.0
  },
  P12: {
    x: -50.0,
    y: 44.15,
    z: 0.0
  },
  P13: {
    x: -50.0,
    y: 25.85,
    z: 0.0
  },
  P14: {
    x: -50.0,
    y: 14.9,
    z: 0.0
  },
  P15: {
    x: -50.0,
    y: 31.34,
    z: 2.44
  },
  P16: {
    x: -50.0,
    y: 38.66,
    z: 2.44
  },
  P17: {
    x: -44.5,
    y: 25.85,
    z: 0.0
  },
  P18: {
    x: -44.5,
    y: 44.15,
    z: 0.0
  },
  P19: {
    x: -39.0,
    y: 35.0,
    z: 0.0
  },
  P20: {
    x: -33.5,
    y: 14.9,
    z: 0.0
  },
  P21: {
    x: -33.5,
    y: 27.69,
    z: 0.0
  },
  P22: {
    x: -33.5,
    y: 42.31,
    z: 0.0
  },
  P23: {
    x: -33.5,
    y: 55.1,
    z: 0.0
  },
  P24: {
    x: -9.15,
    y: 35.0,
    z: 0.0
  },
  P25: {
    x: 0.0,
    y: 35.0,
    z: 0.0
  },
  P26: {
    x: 33.5,
    y: 55.1,
    z: 0.0
  },
  P27: {
    x: 33.5,
    y: 42.31,
    z: 0.0
  },
  P28: {
    x: 33.5,
    y: 27.69,
    z: 0.0
  },
  P29: {
    x: 33.5,
    y: 14.9,
    z: 0.0
  },
  P30: {
    x: 39.0,
    y: 35.0,
    z: 0.0
  },
  P31: {
    x: 44.5,
    y: 44.15,
    z: 0.0
  },
  P32: {
    x: 44.5,
    y: 25.85,
    z: 0.0
  },
  P33: {
    x: 50.0,
    y: 38.66,
    z: 2.44
  },
  P34: {
    x: 50.0,
    y: 31.34,
    z: 2.44
  }
}

export const defaultPitch: SoccerPitch = {
  id: '0',
  name: 'Default Football Pitch',
  type: sportTypeKey.soccer,
  anchors: null,
  arcs: null,
  pitch: {
    length: 0,
    width: 0,
    posts: {
      width: 7.32,
      height: 2.44,
      crossbarHeight: 2.44,
      diameter: 0.12
    },
    coordinates: coordinates
  }
}
