import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EventsFilters } from '../filter'
import {
  setSelectedFlight,
  updateEvent,
  updateSelectedColumns
} from '../actions'
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import {
  useEvents,
  useFormattedEvents,
  useSelectedFormattedEvent
} from '../hooks'
import { useAppDispatch } from '../../../store/hooks'
import { useMetricColumnSelectModal } from '../../../views/Session/modals/column_select'
import { getMostRecentKick } from '../flight/functions'
import { useSelectedFormattedSession } from '../../sessions/hooks'
import { useUnitsSystem } from '../../units/hooks'
import { NewTable } from '../../../components/Table/NewTable'
import { getMetricHeaders } from '../../units/functions'
import Checkbox from '../../../components/Forms/Inputs/Checkbox/Checkbox'
import { TeamLogo } from '../../../components/TeamLogo/TeamLogo'
import { dataTypes } from '../../../components/Table/data_types'
import { RawEventData } from '../types'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { isEventType } from '../data_types'
import { isSportType } from '../../sports/data_types'
import { isSubSessionType } from '../../sessions/sub_sessions/data_types'
import { flightEventTypes } from '../flight/data_types'
import { gameEventTypes } from '../game/data_types'
import * as Sentry from '@sentry/react'
import { Warning } from '@mui/icons-material'
import { sportableColors } from '../../../const'

export interface ValidationTableProps {
  eventsFilters: EventsFilters
  tableId: string
  active: boolean
  updateDisplayedEvents?: (events: RawEventData[]) => void
  maxDisplayedEvents?: number
  validationTableSorting?: SortingState
  setValidationTableSorting?: (sorting: SortingState) => void
  timeColumn: string
  setTimeColumn: (timeColumn: string) => void
  isKeyboardShortcutEnabled?: boolean
}
export const ValidationTable = (props) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <h3>Something went really wrong, contact support immediately</h3>
      }
      onError={console.error}
    >
      <ValidTable {...props} />
    </Sentry.ErrorBoundary>
  )
}

const ValidTable = ({
  eventsFilters,
  tableId,
  active,
  updateDisplayedEvents,
  maxDisplayedEvents,
  validationTableSorting,
  setValidationTableSorting,
  timeColumn,
  setTimeColumn,
  isKeyboardShortcutEnabled
}: ValidationTableProps) => {
  const dispatch = useAppDispatch()

  const formattedSession = useSelectedFormattedSession()

  const { sport, flightMetrics } = formattedSession

  const formattedEvent = useSelectedFormattedEvent()

  // ====== //

  // Redux //
  const events = useEvents()
  const unitSystem = useUnitsSystem(sport)
  // ====== //

  // Modals //
  const { openMetricColumnSelectModal } = useMetricColumnSelectModal(
    flightMetrics,
    events.columns,
    (columns) => {
      dispatch(updateSelectedColumns(columns))
    }
  )
  // ====== //

  const { eventsFilter, filteredEvents, latestEvent } = eventsFilters

  // Temporary fix for memeory leak spotted in AFLW and CFL - will be fixed in the events refactor //

  const validationTableData = useMemo(() => {
    const fitleredEventsMap = {}
    filteredEvents.forEach((event) => {
      fitleredEventsMap[event.id] = event.id
    })
    return events.tableData.filter((tableEvent) => {
      if (fitleredEventsMap[tableEvent.id]) {
        return true
      }
      return false
    })
  }, [events.tableData, filteredEvents])

  // Set most recent kick as selected flight
  const selectedMostRecentKick = useCallback(() => {
    const mostRecentKick = getMostRecentKick(filteredEvents)
    if (mostRecentKick) {
      dispatch(setSelectedFlight(mostRecentKick.id))
    }
    return mostRecentKick
  }, [filteredEvents])

  // const tableHeaders = useValidationTableHeaders(timeColumn)
  const selectedColumns = useMemo(() => {
    return getMetricHeaders(events.columns, flightMetrics, unitSystem, 12.5, 12)
  }, [events.columns, unitSystem])

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'operator',
        header: '',
        size: 5,
        minSize: 5,
        enableResizing: true,
        cell: ({ row }) => {
          const item = row.original

          const handleCheckboxChange = (value) => {
            dispatch(
              updateEvent({
                id: item.id,
                operatorNotes: { ...item.operatorNotes, highlight: value }
              })
            )
          }
          const value = item.highlight
          return (
            <Checkbox
              size='small'
              onClicked={handleCheckboxChange}
              checked={value}
              input={{ value }}
              type='highlight'
            />
          )
        }
      },
      {
        accessorKey: timeColumn,
        header: 'Time',
        size: 14,
        minSize: 14,
        cell: ({ row }) => {
          const item = row.original
          const type = timeColumn === 'sessionStartTime' ? 'time' : 'date'

          const formattedTime = dataTypes[type].display(item[timeColumn])

          return formattedTime
        }
      },
      {
        accessorKey: 'team',
        header: '',
        size: 5,
        minSize: 5,
        cell: ({ row }) => {
          const item = row.original
          if (!item.teamLogo) return
          return (
            <TeamLogo
              color={item.teamLogo?.color}
              logo={item.teamLogo?.logo}
              size={16}
            />
          )
        }
      },
      {
        accessorKey: 'player',
        header: 'Player',
        size: 26.5
      },
      {
        accessorKey: 'typeName',
        header: 'Type',
        size: 19.5
      },
      ...selectedColumns,
      {
        accessorKey: 'ignore',
        header: 'Ignored',
        size: 5,
        minSize: 5,
        cell: ({ row }) => {
          const item = row.original
          const value = item.ignore

          const handleCheckboxChange = () => {
            dispatch(updateEvent({ id: item.id, ignore: !item.ignore }))
          }
          return (
            <Checkbox
              size='small'
              onClicked={handleCheckboxChange}
              checked={value}
              input={{ value }}
              type='checkbox'
              disabled={item.__disabled?.[item.key]}
            />
          )
        }
      }
    ]
  }, [eventsFilters.filteredEvents, timeColumn, selectedColumns])

  const table = useReactTable({
    data: validationTableData,
    columns,
    state: {
      sorting: validationTableSorting
    },
    onSortingChange: setValidationTableSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
    // debugTable: true
  })

  const { flatRows } = table.getRowModel()

  // Update displayed events on table page change
  useEffect(() => {
    if (!updateDisplayedEvents) return
    // Filter out first maxDisplayedEvents unignored events //

    let count = 0
    const eventType = formattedSession.eventTypes.getTypeByValue(
      formattedEvent?.rawData?.event?.type
    )
    const isFormattedFlight = isEventType.flight(eventType)

    const firstMaxRows = flatRows.filter((row, i) => {
      const eventType = formattedSession.eventTypes.getTypeByValue(
        row.original?.rawData?.event.type
      )
      const isFlight = isEventType.flight(eventType)
      if (!row.original.ignore && isFlight && count < maxDisplayedEvents) {
        count++
        return true
      }
      return false
    })
    let rawEvents = firstMaxRows.map((row) => row.original.rawData)
    if (formattedEvent && isFormattedFlight) {
      rawEvents = [...rawEvents, formattedEvent.rawData]
    }

    updateDisplayedEvents(rawEvents)
  }, [
    flatRows,
    updateDisplayedEvents,
    maxDisplayedEvents,
    active,
    formattedEvent,
    validationTableSorting
  ])

  // Set most recent Pass as selected flight (CFL)
  useEffect(() => {
    if (!latestEvent) {
      return
    }
    const isPass =
      latestEvent.type === flightEventTypes.items.pass.value ||
      latestEvent.type === gameEventTypes.items.manualPass.value
    const isBroadcast = isSubSessionType.broadcast(formattedSession.subType)
    const isTest = isSubSessionType.test(formattedSession.subType)
    const isCFL = isSportType.canadianFootball(sport)
    if (isPass && (isBroadcast || isTest) && isCFL && !latestEvent.ignore) {
      dispatch(setSelectedFlight(latestEvent.id))
    }
  }, [latestEvent, formattedSession, sport])

  // Generate table controls //
  const controls = useMemo(() => {
    return [
      {
        name: 'Toggle Time',
        callback: () => {
          timeColumn === 'sessionStartTime'
            ? setTimeColumn('startTimeMil')
            : setTimeColumn('sessionStartTime')
        }
      },
      {
        name: !eventsFilter.filters.ignored.value
          ? 'Show Ignored'
          : 'Show Only Valid',
        callback: () => {
          eventsFilter.updateFilterValue(
            'ignored',
            !eventsFilter.filters.ignored.value
          )
        }
      },
      {
        name: eventsFilter.filters.highlighted.value
          ? 'Show All'
          : 'Show Key Events',
        callback: () => {
          eventsFilter.updateFilterValue(
            'highlighted',
            !eventsFilter.filters.highlighted.value
          )
        }
      },
      {
        name: 'Add/Remove Metrics',
        callback: () => openMetricColumnSelectModal(2)
      }
    ]
  }, [eventsFilter, timeColumn, openMetricColumnSelectModal])

  // TAB favourite star
  const updateFavouriteHotKey = useCallback(() => {
    if (active && isKeyboardShortcutEnabled) {
      if (formattedEvent && 'operatorNotes' in formattedEvent.rawData) {
        const data = { ...formattedEvent.rawData }
        if (data.operatorNotes) {
          data.operatorNotes.highlight = !data.operatorNotes.highlight
        } else {
          data.operatorNotes = {
            id: null,
            highlight: true,
            notes: '',
            matchTime: null
          }
        }
        dispatch(updateEvent(data))
      }
    }
  }, [formattedEvent])

  useKeyboardShortcut(['f'], updateFavouriteHotKey, {
    overrideSystem: isKeyboardShortcutEnabled
  })

  // Tab shortcut - ignore event
  const updateIgnoreCheckboxHotKey = useCallback(() => {
    if (active && isKeyboardShortcutEnabled) {
      if (formattedEvent && 'ignore' in formattedEvent.rawData) {
        const data = {
          id: formattedEvent.rawData.id,
          ignore: !formattedEvent.rawData.ignore
        }
        dispatch(updateEvent(data))
      }
    }
  }, [formattedEvent])

  useKeyboardShortcut(['Tab'], updateIgnoreCheckboxHotKey, {
    overrideSystem: isKeyboardShortcutEnabled
  })

  return (
    <NewTable
      active={active}
      table={table}
      title={`${filteredEvents.length}`}
      handleShiftUpShortcut={selectedMostRecentKick}
      controls={controls}
    />
  )
}
