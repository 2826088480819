export type EventType = {
  name: string
  value: string
  oldValue: number
}

export type EventTypesType = {
  pass: EventType
  kick: EventType
  lineout: EventType
  soccerPass: EventType
  soccerShotAtGoal: EventType
  soccerKickOff: EventType
  soccerThrowIn: EventType
  soccerCorner: EventType
  soccerPenalty: EventType
  soccerDribble: EventType
  soccerCross: EventType
  soccerClearance: EventType
  soccerDeflection: EventType
  snap: EventType
}

export const eventTypes: EventTypesType = {
  pass: { name: 'Pass', value: 'Pass', oldValue: 0 },
  kick: { name: 'Kick', value: 'Kick', oldValue: 1 },
  lineout: { name: 'Lineout', value: 'Lineout', oldValue: 2 },
  soccerPass: { name: 'Football Pass', value: 'SoccerPass', oldValue: 6 },
  soccerShotAtGoal: {
    name: 'Shot At Goal',
    value: 'SoccerShotAtGoal',
    oldValue: 7
  },
  soccerKickOff: { name: 'Kick off', value: 'SoccerKickOff', oldValue: 8 },
  soccerThrowIn: { name: 'Throw In', value: 'SoccerThrowIn', oldValue: 9 },
  soccerCorner: { name: 'Corner', value: 'SoccerCorner', oldValue: 10 },
  soccerPenalty: { name: 'Penalty', value: 'SoccerPenalty', oldValue: 11 },
  soccerDribble: { name: 'Dribble', value: 'SoccerDribble', oldValue: 12 },
  soccerCross: { name: 'Cross', value: 'SoccerCross', oldValue: 13 },
  soccerClearance: {
    name: 'Clearance',
    value: 'SoccerClearance',
    oldValue: 14
  },
  soccerDeflection: {
    name: 'Deflection',
    value: 'SoccerDeflection',
    oldValue: 15
  },
  snap: { name: 'Snap', value: 'Snap', oldValue: 16 }
}

export function getOldEventType(type: number) {
  let eventTypeObj: EventType
  for (const key in eventTypes) {
    if (eventTypes[key].oldValue === type) eventTypeObj = eventTypes[key]
  }
  return eventTypeObj
}

export function getEventType(type: string) {
  let eventTypeObj: EventType
  for (const key in eventTypes) {
    if (eventTypes[key].value === type) eventTypeObj = eventTypes[key]
  }
  return eventTypeObj
}
