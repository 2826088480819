import { Buffer } from 'buffer'

import { setError } from '../../ui/error/actions'
import { addNotification } from '../notifications/actions'
import { setPitchInUse } from '../pitches/actions'
import { clearSessionEvents } from '../events/actions'
import { clearSessionChallenges } from '../targets/actions'
import { request } from '../../utils/request_handler'
import { API_ROOT_URL } from '../../const'
import axios from 'axios'
import {
  FormattedSession,
  RawPlayerSessionData,
  RawSessionData,
  SessionFilterRequestBody,
  SessionsActionType
} from './types'
import saveAs from 'file-saver'
import { requestAction } from '../api/request'
import * as api from './api'
import { getUnitSystemFromSession } from '../units/functions'
import { AppDispatch } from '../../store'
import { removeBroadcastIntegrationData } from '../broadcast_integration/slice'
import { clearSportscaster } from '../sportscaster/actions'
import { isSessionStateType } from './states/data_types'
import { isSessionType } from './data_types'
import { setInfo } from '../../ui/info/actions'
import { SessionDownloadType } from './hooks'
import { User } from '../user/types'

const {
  CHECK_ACTIVE_SESSION,
  GET_RECENT_SESSIONS,
  UPDATE_FILTER_OPTIONS,
  FETCH_FILTERED_SESSIONS,
  SET_FILTERED_SESSIONS,
  FETCH_FILTERED_SESSIONS_FAILED,
  SET_SESSION,
  EXIT_SESSION,
  UPDATE_SELECTED_SESSION,
  SET_SETUP_SESSION,
  UPDATE_SETUP_SESSION,
  ADD_PLAYER_SESSION,
  UPDATE_PLAYER_SESSION,
  REMOVE_PLAYER_SESSION,
  REMOVE_BALL_FROM_SESSION,
  CREATE_SESSION,
  CREATING_SESSION,
  STOP_SESSION,
  START_SESSION,
  SET_ACTIVITY_LIST_SCROLL_HEIGHT,
  UPDATE_SESSION,
  SET_ACTIVE_SESSION,
  UPDATE_BROADCAST_STATE,
  GET_SCOREBOARD,
  SET_AUTO_SLEEP,
  SET_SESSION_UPLOAD_STATUS,
  UPDATE_USE_CUSTOM_NAME,
  SET_CUSTOM_NAME,
  UPDATE_SET_OFFICIATION,
  TOGGLE_KEYBOARD_SHORTCUTS
} = SessionsActionType

// Check active session
export function checkActiveSession() {
  return (dispatch: AppDispatch) => {
    const success = (response) => {
      dispatch(setActiveSession(response.data ? response.data : null))
    }
    const error = (error, errorType) => {
      dispatch(setActiveSession(null))
    }
    const options = {
      url: `config/activesession`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error, dispatch)
  }
}

export function setActiveSession(session) {
  return {
    type: SET_ACTIVE_SESSION,
    payload: session
  }
}

// Get recent sessions
export function getRecentSessions(
  data,
  callback: (sessions: RawSessionData[]) => void
) {
  return (dispatch) => {
    const success = (response) => {
      const sortedRecentSessions = response.data.sort((a, b) => {
        if (a.startTime > b.startTime) return -1
        if (a.startTime < b.startTime) return 1
        return 0
      })
      dispatch({
        type: GET_RECENT_SESSIONS,
        payload: sortedRecentSessions
      })
      if (callback) callback(sortedRecentSessions)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get teams recent sessions. API request failed. Check log for more detail.`
        })
      )
    }
    return getSessions(success, error, data, dispatch)
  }
}

export function getTeamsMostRecentSession(
  teamId,
  sessionType,
  sportType,
  callback
) {
  return (dispatch) => {
    const success = (response) => {
      if (callback) callback(response.data)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get most recent session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `/sessions/${teamId}/latest?sessionType=${sessionType}&sport=${sportType}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

export function updateFilterOptions(
  options: Partial<SessionFilterRequestBody>
) {
  return {
    type: UPDATE_FILTER_OPTIONS,
    payload: options
  }
}

export function getFilteredSessions(data: Partial<SessionFilterRequestBody>) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_FILTERED_SESSIONS,
      payload: data
    })
    const success = (response) => {
      dispatch({
        type: SET_FILTERED_SESSIONS,
        payload: { data: response.data }
      })
    }
    const error = (error, errorType) => {
      dispatch({
        type: FETCH_FILTERED_SESSIONS_FAILED
      })
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get filtered sessions. API request failed. Check log for more detail.`
        })
      )
    }

    const fetchData = {
      ...getState().sessions.filter.options,
      ...data
    }
    delete fetchData.type
    delete fetchData.subType

    if (fetchData.team === 'All') {
      fetchData.teamIds = []
    } else {
      fetchData.teamIds = [fetchData.team]
    }

    getSessions(success, error, fetchData, dispatch)
  }
}

// get Scoreboard
export function getScoreboard(sessionId) {
  return (dispatch) => {
    const success = (response) => {
      const data = response.data || []
      dispatch({
        type: GET_SCOREBOARD,
        payload: data
      })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get scoreboard. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `/sessions/${sessionId}/scoreboard`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error, dispatch)
  }
}

// Get filtered sessions req
export function getSessions(success, error, data, dispatch) {
  const options = {
    url: 'sessions/filters',
    data,
    method: 'post',
    baseURL: API_ROOT_URL,
    withCredentials: true
  }
  return request(options, success, error, dispatch)
}

// Set session
export function getSession(session: FormattedSession, callback) {
  return (dispatch, getState) => {
    // Check if session is viewable before setting
    if (isSessionType.match(session.type) && !session.awayTeam) {
      dispatch(
        setInfo({
          message: `Missing team in this session.`
        })
      )
    } else if (session.sport.isUnknown()) {
      dispatch(setInfo({ message: 'Sport Type Not Supported' }))
    } else {
      const type = isSessionStateType.notStarted(session.state)
        ? 'setup'
        : 'selected'
      dispatch(getSessionById(session.id, type, callback))
    }
  }
}

export function setSession(payload, type) {
  const actionType = getActionTypes(type)
  return {
    type: actionType.setAction,
    payload: payload
  }
  return (dispatch, getState) => {
    // If session is scheduled or live and has pitch config then set pitch config
    // Do not set pitch config if active session is running
    // let { sessions } = getState()
    // console.log(sessions, payload, sessionStates.finished.value)
    // if (
    //   payload.pitch &&
    //   payload.pitch.id &&
    //   payload.state !== sessionStates.finished.value &&
    //   !sessions.activeSession
    // ) {
    //   dispatch(setPitchConfig(payload.pitch.id))
    // }
  }
}

// Set custom name for session form //
export function updateUseCustomName(value: boolean) {
  return {
    type: UPDATE_USE_CUSTOM_NAME,
    payload: value
  }
}

export function setCustomName(name) {
  return {
    type: SET_CUSTOM_NAME,
    payload: name
  }
}
// ideally we want a CLEAR_SESSION which returns state back to initial, currently doesn't work with useSession()
export function exitSession() {
  return {
    type: EXIT_SESSION
  }
}
// ================== //

export function updateSession(payload) {
  return {
    type: UPDATE_SESSION,
    payload: payload
  }
}

function getActionTypes(type) {
  let setAction, updateAction
  switch (type) {
    case 'selected':
      setAction = SET_SESSION
      updateAction = UPDATE_SELECTED_SESSION
      break
    case 'setup':
      setAction = SET_SETUP_SESSION
      updateAction = UPDATE_SETUP_SESSION
      break
    default:
      setAction = ''
      updateAction = ''
      break
  }
  return {
    setAction,
    updateAction
  }
}

export function getSessionById(sessionId, type, callback) {
  return (dispatch) => {
    dispatch(clearSessionChallenges(sessionId))
    dispatch(clearSessionEvents())
    const success = (response) => {
      // TODO: Remove this when we replace selected and setup session with one session
      dispatch(setSession({ ...response.data, fetch: true }, type))
      // ===== //
      dispatch(updateSession(response.data))
      if (callback) callback()
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

export function updateSelectedSession(sessionId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({ ...response.data, fetch: true })
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

export function getPlayerSessions(sessionId, type, callback) {
  return (dispatch) => {
    const success = (response) => {
      dispatch(
        updateSession({ id: sessionId, playersSessions: response.data || [] })
      )
      if (callback) callback()
    }

    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to get player_sessions. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/players_sessions`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

// Get session setup
export function getPreviousSessionSetup(sessionId, teamIds, organisationId) {
  return (dispatch) => {
    axios.all([dispatch(getPlayerSessions(sessionId, 'setup', null))])
  }
}

// Create session
export function createSession(data: RawSessionData, callback) {
  return (dispatch) => {
    dispatch({ type: CREATING_SESSION, payload: true })
    // Manually set start time
    data.startTime = new Date().getTime() / 1000 + 86400

    const success = (response) => {
      localStorage.setItem('prevSession', JSON.stringify(data))
      setTimeout(() => {
        if (callback) callback(response.data)
        dispatch(updateSession(response.data))
        dispatch({
          type: START_SESSION
        })
        dispatch({ type: CREATING_SESSION, payload: false })
      }, 1000)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to create session. API request failed. Check log for more detail.`
        })
      )
      dispatch({ type: CREATING_SESSION, payload: false })
    }
    const options = {
      url: 'sessions',
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error)
  }
}

// Save Session
export function saveSession(data, callback) {
  return (dispatch) => {
    dispatch({ type: CREATING_SESSION, payload: true })
    const success = (response) => {
      if (callback) callback(response.data)
      dispatch(updateSession(response.data))
      dispatch({
        type: START_SESSION
      })
      dispatch({ type: CREATING_SESSION, payload: false })
      dispatch(setPitchInUse(response.data.pitch))
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to save session. API request failed. Check log for more detail.`
        })
      )
      dispatch({ type: CREATING_SESSION, payload: false })
    }
    const options = {
      url: `sessions/${data.id}`,
      data,
      method: 'patch',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

// Start session
export function startSession(data, callback) {
  return (dispatch) => {
    dispatch({ type: CREATING_SESSION, payload: true })
    const success = (response) => {
      dispatch(clearSessionChallenges(response.data.id))
      dispatch(setActiveSession(response.data))
      dispatch(setSession(response.data, 'selected'))
      dispatch({
        type: START_SESSION
      })
      dispatch({ type: CREATING_SESSION, payload: false })
      if (callback) callback(response.data)
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to start session. API request failed. Check log for more detail.`
        })
      )
      dispatch({ type: CREATING_SESSION, payload: false })
    }
    const options = {
      url: `sessions/${data.id}/start`,
      data: {},
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

// Stop session
export function stopSession(sessionId, callback) {
  return (dispatch) => {
    const success = (response) => {
      dispatch(setActiveSession(null))
      dispatch(removeBroadcastIntegrationData())
      dispatch(clearSportscaster())
      dispatch({
        type: STOP_SESSION
      })
      if (callback) callback()
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to stop session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}`,
      method: 'put',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error)
  }
}

// Update Session
export function patchSession(data, callback?) {
  return (dispatch) => {
    const success = (response) => {
      dispatch(updateSession(response.data))
      if (callback) callback()
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to update session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${data.id}`,
      data,
      method: 'patch',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error)
  }
}

export function addPlayerSession(playerSession: RawPlayerSessionData) {
  return {
    type: ADD_PLAYER_SESSION,
    payload: playerSession
  }
}

export function updatePlayerSession(teamId, number, data, playerId) {
  return {
    type: UPDATE_PLAYER_SESSION,
    payload: { teamId, number, data, playerId }
  }
}

export function removeBallFromSession(ballId) {
  return {
    type: REMOVE_BALL_FROM_SESSION,
    payload: ballId
  }
}

export function removePlayerSession(playerId) {
  return {
    type: REMOVE_PLAYER_SESSION,
    payload: playerId
  }
}

export function removeTagFromPlayer(playerSession) {
  return (dispatch) => {
    dispatch(removePlayerSession(playerSession.playerId))
    const playerSessionWithoutTag = { ...playerSession }
    playerSessionWithoutTag.id = null
    playerSessionWithoutTag.tag = null
    dispatch(addPlayerSession(playerSessionWithoutTag))
  }
}

function getUser(): User | undefined {
  if (localStorage) {
    const user: User = JSON.parse(localStorage.getItem('user'))
    if (user) {
      return user
    }
  }
  return undefined
}

export function downloadDataFromSessionEndpoint(
  sessionId: string,
  type: SessionDownloadType
) {
  return (dispatch) => {
    const success = (response) => {
      const user = getUser()
      dispatch(
        addNotification({
          text: `Downloading ${type} for ${sessionId}. An email will be sent to ${
            user?.email || 'your address'
          } within 10 minutes`
        })
      )
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to download data. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/download/${type}`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error)
  }
}

export function downloadPlayerPositionsPerEvent(sessionId: string) {
  return (dispatch) => {
    const success = (response) => {
      const user = getUser()
      dispatch(
        addNotification({
          text: `Downloading events players positions for session ${sessionId}. An email will be sent to ${
            user?.email || 'your address'
          } within 10 minutes`
        })
      )
    }

    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to download player positions per event data. API request failed. Check log for more detail.`
        })
      )
    }

    const options = {
      url: `sessions/${sessionId}/download/events_players_positions`,
      method: 'get',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }

    request(options, success, error)
  }
}

// download session flights
export type SessionFlightDownloadRequestBody = {
  offset: number
  team: string
  xml: boolean
  csv: boolean
}

export function downloadFlights(
  data: SessionFlightDownloadRequestBody,
  sessionId: string,
  timezone: string,
  drills: boolean
) {
  return (dispatch, getState) => {
    const { units } = getState()
    const session = getState().sessions.rawData[sessionId]
    const unitSystem = getUnitSystemFromSession(session, units)

    const success = (response) => {
      dispatch(
        addNotification({
          text: 'Downloading data. An email will be sent to your address containing the files'
        })
      )
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? `${error.response.data[errorType]}, Check session does not have ignored flights.`
              : `Failed to download data. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/download/flights?units=${
        unitSystem.key
      }&timeZone=${timezone}&drills=${drills || false}`,
      data,
      method: 'post',
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    request(options, success, error)
  }
}

// download session raw session data
export function downloadPitchForSession(
  sessionId: string,
  sessionName: string
) {
  return (dispatch) => {
    const success = (response) => {
      if (response.status === 200) {
        const headerLine = response.headers['content-disposition']

        const search = ' '
        const replaceWith = '_'
        let filename = sessionName.split(search).join(replaceWith)

        if (headerLine) {
          filename = headerLine
            .replace(/[\w; ]+filename="/g, '')
            .replace(/"/g, '')
        }

        const content = Buffer.from(response.data, 'hex')
        const blob = new Blob([content], { type: 'application/zip' })

        saveAs(blob, filename)
      }
    }
    const error = (error, errorType) => {
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to download data. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `sessions/${sessionId}/pitch`,
      method: 'GET',
      baseURL: API_ROOT_URL,
      withCredentials: true,
      responseType: 'arraybuffer'
    }
    request(options, success, error)
  }
}

// Save activity list scroll height
export function setActivityListScrollHeight(height: number) {
  return {
    type: SET_ACTIVITY_LIST_SCROLL_HEIGHT,
    payload: height
  }
}

// Auto sleep

export async function getAutoSleep() {
  return (dispatch) => {
    const success = (response) => {
      dispatch(setAutoSleep(response.data))
    }
    return dispatch(requestAction(api.getAutoSleep, success))
  }
}

export function updateAutoSleep(isAutoSleepEnabled) {
  return (dispatch) => {
    const success = (response) => {
      dispatch(setAutoSleep(response.data?.IsAutoSleepOn))
    }
    const data = { IsAutoSleepOn: isAutoSleepEnabled }
    return dispatch(requestAction(api.updateAutoSleep, success, data))
  }
}

// Upload Session

export function setUploadStatus(sessionId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: SET_SESSION_UPLOAD_STATUS,
        payload: { id: sessionId, data: response.data }
      })
    }
    const error = (error, errorType) => {
      dispatch({
        type: SET_SESSION_UPLOAD_STATUS,
        payload: { id: sessionId, data: null }
      })
      dispatch(
        setError({
          message:
            error.response &&
            error.response.data &&
            error.response.data[errorType]
              ? error.response.data[errorType]
              : `Failed to Upload Session. API request failed. Check log for more detail.`
        })
      )
    }
    const options = {
      url: `/sessions/${sessionId}/upload`,
      method: 'post',
      sessionId,
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}

export function getUploadStatus(sessionId) {
  return (dispatch) => {
    const success = (response) => {
      dispatch({
        type: SET_SESSION_UPLOAD_STATUS,
        payload: { id: sessionId, data: response.data }
      })
    }
    const error = (error, errorType) => {
      dispatch({
        type: SET_SESSION_UPLOAD_STATUS,
        payload: { id: sessionId, data: null }
      })
      // dispatch(
      //   setError({
      //     message:
      //       error.response &&
      //       error.response.data &&
      //       error.response.data[errorType]
      //         ? error.response.data[errorType]
      //         : `Failed to Upload Session. API request failed. Check log for more detail.`
      //   })
      // )
    }
    const options = {
      url: `/sessions/${sessionId}/upload`,
      method: 'get',
      sessionId,
      baseURL: API_ROOT_URL,
      withCredentials: true
    }
    return request(options, success, error)
  }
}
export function toggleKeyboardShortcuts(bool) {
  return {
    type: TOGGLE_KEYBOARD_SHORTCUTS,
    payload: bool
  }
}

export function setAutoSleep(data) {
  return {
    type: SET_AUTO_SLEEP,
    payload: data
  }
}
