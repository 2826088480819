import { FormattedSession, SessionConfig } from '../../sessions/types'
import { eventTypes } from '../data_types'
import { AllEventTypeKeys } from '../filter'
import { GameEventTypeGroup, GameEventTypeValues } from './data_types'
import { GameEventSubTypeValues } from './subType/data_types'
import { GameEventData, RawGameEventData } from './types'
import { FormattedEventData } from '../types'
import { UnitSystem } from '../../units/types'
import { outcomeTypes } from '../../outcomes/data_types'
import { formatMetrics } from '../../metrics/functions'

type TableRow = GameEventData & {
  typeName: string
  sessionStartTime: number
  startTime: string
  startTimeMil: number
  fromPlayer: string
  teamLogo: {
    logo: string
    color: string
  }
  __color?: string
  __backgroundColor?: string
}

type HiddenConfig = {
  deleteTimeEvent: boolean
}

export type CodingTableRow = {
  typeName: string
  type: GameEventTypeValues
  subType: GameEventSubTypeValues
  startTime: number
  id: string
  eventId: string
  __hidden?: HiddenConfig
  teamLogo: {
    logo: string
    color: string
  }
}

export const generateCodingTableGameEventRowData = (
  gameEvent: GameEventData,
  sessionConfig: SessionConfig
) => {
  const gameEventType = sessionConfig.gameEventTypes.getTypeByValue(
    gameEvent.type
  )

  const row: CodingTableRow = {
    typeName: gameEventType.name,
    type: gameEvent.type,
    subType: gameEvent.subType,
    startTime: gameEvent.event?.startTime * 1000,
    id: `${gameEvent.id}-gameEvent`,
    eventId: gameEvent.eventId,
    __hidden: {
      deleteTimeEvent: true
    },
    teamLogo: sessionConfig.sessionTeams[gameEvent.teamId]?.team || {
      logo: '',
      color: 'black'
    }
  }

  return row
}

export const filterGameEvents = (
  gameEvents: GameEventData[],
  { possession, type, team },
  visibleEventTypeKeys: AllEventTypeKeys[],
  gameEventTypes: GameEventTypeGroup
) => {
  const filteredGameEvents = []
  gameEvents.forEach((gameEvent) => {
    if (eventTypes.isType('game', gameEvent?.event?.type)) {
      const gameEventType = gameEventTypes.getTypeByValue(gameEvent.type)
      if (type.value !== 'All') {
        if (!gameEventType) return
        if (!gameEventTypes.isType(gameEventType.key, type.value)) return
      }
      if (team.value !== 'All') {
        if (team.value !== gameEvent.teamId) return
      }
      if (!visibleEventTypeKeys.includes(gameEventType?.key)) return
      if (
        gameEventTypes.isType('possession', gameEventType?.value) &&
        possession.value
      )
        return
      filteredGameEvents.push(gameEvent)
    }
  })
  return filteredGameEvents
}

export const generateFormattedGameData = (
  eventType: typeof eventTypes.items.game,
  gameEvent: RawGameEventData,
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
): FormattedEventData => {
  const { teams, players, gameEventTypes, startTime } = formattedSession
  const sessionStartTime =
    (gameEvent.event.startTime - startTime.unixSeconds) * 1000
  const team = teams.map[gameEvent.teamId]
  const player = players?.[team ? team.id : 'all'].map[gameEvent.playerId]
  const gameEventType = gameEventTypes.getTypeByValue(gameEvent.type)
  const gameEventSubType = gameEventType.props.types?.isEmpty()
    ? gameEventType.props.types.getTypeByValue(gameEvent.subType)
    : null
  const operatorNotes = gameEvent.operatorNotes
    ? {
        id: gameEvent.operatorNotes.id,
        notes: gameEvent.operatorNotes.notes,
        highlight: gameEvent.operatorNotes.highlight,
        matchTime: gameEvent.operatorNotes.matchTime
      }
    : null
  const ignore = gameEvent.ignore

  const metrics = formatMetrics(
    gameEventType.props.metricTypes,
    gameEvent,
    formattedSession,
    unitSystem
  )

  const features =
    gameEventSubType && !gameEventSubType.isUnknown()
      ? gameEventSubType?.props.features
      : gameEventType.props.features

  const formattedGameEventData = {
    id: gameEvent.id,
    rawData: gameEvent,
    eventType: eventType.value,
    sessionId: gameEvent.event.sessionId,
    sessionStartTime: sessionStartTime,
    startTime: gameEvent.event.startTime,
    endTime: gameEvent.event.endTime,
    ignore: ignore,
    operatorNotes: operatorNotes,
    typeName:
      gameEventSubType && !gameEventSubType.isUnknown()
        ? gameEventSubType?.name
        : gameEventType.name,
    type: {
      selected: gameEventType,
      options: gameEventTypes?.options
    },
    subType:
      !gameEventType.isUnknown() && !gameEventType?.props?.types?.isEmpty()
        ? {
            selected: gameEventType.props.types.getTypeByValue(
              gameEvent.subType
            ),
            options: gameEventType.props.types.options
          }
        : null,
    team: {
      selected: team,
      options: teams?.optionsWithNull
    },
    player: {
      selected: player,
      options: players?.[team ? team.id : 'all'].optionsWithNull
    },
    metrics: metrics,

    compareTagPosition: {
      label: 'X Distance to event',
      x: gameEvent.positionX,
      y: gameEvent.positionY,
      z: gameEvent.positionZ
    },

    features
  }

  return formattedGameEventData
}
