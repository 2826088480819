import _ from 'lodash'
import {
  CHANGE_EVENT_FILTER_TYPE,
  SET_SESSION_TIME_EVENTS,
  SET_SESSION_GAME_EVENTS,
  SELECT_EVENT,
  GET_EVENT,
  UPDATE_SELECTED_COLUMNS,
  SET_PENALTY_COUNTDOWN_ACTIVE,
  CLEAR_SESSION_EVENTS,
  ADD_EVENT,
  GET_SESSION_EVENTS,
  UPDATE_EVENT,
  GET_LINE_GRAPH_DATA,
  GET_FLIGHT,
  SET_STOPPAGE_LOADING,
  RESET_SELECTED_EVENT
} from './types'
import { SessionsActionType } from '../sessions/types'
import { getOldEventType } from '../../constants/eventTypes'
import { EventsState } from './types'
import { eventTypes } from './data_types'
import { updateFlightWithBouncedToTouch } from './flight/functions'
import { sessionTypesConfig } from '../sessions/data_types'
import { sportTypes } from '../sports/data_types'
import { REMOVE_USER_DATA } from '../types'
import { formatEventData, getTableEvent } from './functions'
import { SI } from '../units/unit_systems'

const { SET_SESSION } = SessionsActionType

const initialState: EventsState = {
  rawData: {},
  items: {},
  tableData: [],
  selectedEventId: null,
  //   '1686040069060505243': {
  //     event: {
  //       id: '1686040069060505243',
  //       sessionId: '1704928053199757573',
  //       startTime: 1704755500.8980316,
  //       endTime: 1704755501.6646983,
  //       type: 0
  //     },
  //     sessionId: '1704928053199757573',
  //     startTime: 1704755500.8980316,
  //     timeEnd: 1704755501.6646983,
  //     id: '1686040069060505243',
  //     eventId: '1633952123724890713',
  //     ballId: 850592345,
  //     fromId: 413401210,
  //     fromPlayerId: '1599127330331953085',
  //     toId: 413401235,
  //     toPlayerId: '1609759566219173655',
  //     hangTime: 0.45,
  //     dist: 1.99,
  //     speed: 4.863,
  //     maxHeight: 5.233,
  //     spin: 3.087,
  //     efficiency: 0.967,
  //     endOverEndEfficiency: 0.137,
  //     type: 'Kick',
  //     subType: 0,
  //     ignore: false,
  //     success: false,
  //     hitPost: false,
  //     offsetX: 0,
  //     offsetY: 0,
  //     offsetZ: 0,
  //     data: [
  //       {
  //         tagId: 850592345,
  //         timestamp: 1633952123.152,
  //         tslocal: 0,
  //         pos: { x: -4.28, y: 9.24, z: 1 },
  //         vel: { x: 0, y: 0, z: 0 },
  //         variance: { x: 0, y: 0, z: 0 },
  //         dist: 0,
  //         confidence: 0,
  //         type: 0,
  //         ballStats: {},
  //         yawPitchRoll: {}
  //       },
  //       {
  //         tagId: 850592345,
  //         timestamp: 1633952123.252,
  //         tslocal: 0,
  //         pos: { x: -4.51, y: 8.83, z: 1.17 },
  //         vel: { x: 0, y: 0, z: 0 },
  //         variance: { x: 0, y: 0, z: 0 },
  //         dist: 0,
  //         confidence: 0,
  //         type: 0,
  //         ballStats: {},
  //         yawPitchRoll: {}
  //       },
  //       {
  //         tagId: 850592345,
  //         timestamp: 1633952123.352,
  //         tslocal: 0,
  //         pos: { x: -4.75, y: 8.4, z: 1.24 },
  //         vel: { x: 0, y: 0, z: 0 },
  //         variance: { x: 0, y: 0, z: 0 },
  //         dist: 0,
  //         confidence: 0,
  //         type: 0,
  //         ballStats: {},
  //         yawPitchRoll: {}
  //       },
  //       {
  //         tagId: 850592345,
  //         timestamp: 1633952123.452,
  //         tslocal: 0,
  //         pos: { x: -4.97, y: 7.95, z: 1.22 },
  //         vel: { x: 0, y: 0, z: 0 },
  //         variance: { x: 0, y: 0, z: 0 },
  //         dist: 0,
  //         confidence: 0,
  //         type: 0,
  //         ballStats: {},
  //         yawPitchRoll: {}
  //       },
  //       {
  //         tagId: 850592345,
  //         timestamp: 1633952123.552,
  //         tslocal: 0,
  //         pos: { x: -5.2, y: 7.47, z: 1.1 },
  //         vel: { x: 0, y: 0, z: 0 },
  //         variance: { x: 0, y: 0, z: 0 },
  //         dist: 0,
  //         confidence: 0,
  //         type: 0,
  //         ballStats: {},
  //         yawPitchRoll: {}
  //       }
  //     ],
  //     raw_data: null,
  //     polynomialCoefficients: [
  //       [-4.278688970318476, 9.236080409554587, 1],
  //       [-2.379029325782149, -3.971605031438358, 2.187487771543336],
  //       [0.21481155106772576, -1.1029993081976954, -4.857264049908132]
  //     ],
  //     reloadTime: 1.300049,
  //     groundReloadTime: 0,
  //     teamID: '102099706615955457',
  //     inPitchHangTime: 0.4313648,
  //     positionExitedPitch: { x: 0, y: 0, z: 0 },
  //     latencyTestSent: '0001-01-01T00:00:00Z',
  //     inPitchDistance: 1.99
  //   },
  //   '1686040069060340064': {
  //     id: '1686040069060340064',
  //     eventId: '1686040069060505247',
  //     event: {
  //       id: '1686040069060505247',
  //       sessionId: '1704928053199757573',
  //       startTime: 1686040069.0605288,
  //       endTime: 1686040069.0605288,
  //       type: 6
  //     },
  //     ballInPlay: '851642970',
  //     type: 'TOUCH',
  //     subType: 'UNCLASSIFIED',
  //     teamId: '1673879431086364588',
  //     confirmed: false,
  //     ignore: false,
  //     positionX: 60.0013951741053261912,
  //     positionY: -0.4824121003114121,
  //     positionZ: 0.01618174252629867,
  //     associated_event_id: '1686040069060505243',
  //     goalLineGraph: {
  //       spinGraph: [
  //         {
  //           X: 1704755500.7980316,
  //           Y: 1.1578607932092788
  //         },
  //         {
  //           X: 1704755501.031365,
  //           Y: 0.7807234399907204
  //         },
  //         {
  //           X: 1704755501.2646983,
  //           Y: 0.1606703807104623
  //         },
  //         {
  //           X: 1704755501.2980316,
  //           Y: 1.4024183595557067
  //         },
  //         {
  //           X: 1704755501.3313649,
  //           Y: 2.550030121745915
  //         },
  //         {
  //           X: 1704755501.3646982,
  //           Y: 2.3153213740839176
  //         },
  //         {
  //           X: 1704755501.4313648,
  //           Y: 1.9824973581948562
  //         },
  //         {
  //           X: 1704755501.4980316,
  //           Y: 0.8457288196104594
  //         },
  //         {
  //           X: 1704755501.5646982,
  //           Y: 0.10290571772977197
  //         },
  //         {
  //           X: 1704755501.5980315,
  //           Y: 0.1317649608736179
  //         },
  //         {
  //           X: 1704755501.6313648,
  //           Y: 0.1799671793315899
  //         },
  //         {
  //           X: 1704755501.6646981,
  //           Y: 0.108677666373175
  //         },
  //         {
  //           X: 1704755501.6980314,
  //           Y: 0.4981903999450994
  //         },
  //         {
  //           X: 1704755501.731365,
  //           Y: 0.1840464857466021
  //         },
  //         {
  //           X: 1704755501.7646983,
  //           Y: 0.22903346241624034
  //         }
  //       ],
  //       impactGraph: [
  //         {
  //           X: 1704755500.7980316,
  //           Y: 1.1578607932092788
  //         },
  //         {
  //           X: 1704755501.031365,
  //           Y: 0.7807234399907204
  //         },
  //         {
  //           X: 1704755501.2646983,
  //           Y: 0.1606703807104623
  //         },
  //         {
  //           X: 1704755501.2980316,
  //           Y: 1.4024183595557067
  //         },
  //         {
  //           X: 1704755501.3313649,
  //           Y: 2.550030121745915
  //         },
  //         {
  //           X: 1704755501.3646982,
  //           Y: 2.3153213740839176
  //         },
  //         {
  //           X: 1704755501.4313648,
  //           Y: 1.9824973581948562
  //         },
  //         {
  //           X: 1704755501.4980316,
  //           Y: 0.8457288196104594
  //         },
  //         {
  //           X: 1704755501.5646982,
  //           Y: 0.10290571772977197
  //         },
  //         {
  //           X: 1704755501.5980315,
  //           Y: 0.1317649608736179
  //         },
  //         {
  //           X: 1704755501.6313648,
  //           Y: 0.1799671793315899
  //         },
  //         {
  //           X: 1704755501.6646981,
  //           Y: 0.108677666373175
  //         },
  //         {
  //           X: 1704755501.6980314,
  //           Y: 0.4981903999450994
  //         },
  //         {
  //           X: 1704755501.731365,
  //           Y: 0.1840464857466021
  //         },
  //         {
  //           X: 1704755501.7646983,
  //           Y: 0.22903346241624034
  //         }
  //       ],
  //       impactEvents: [
  //         {
  //           X: 1704755501.2646983,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755501.4313648,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755501.4980316,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755501.5646982,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755501.5980315,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755501.6313648,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755501.6646981,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755501.6980314,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755500.7980316,
  //           Y: 1
  //         },
  //         {
  //           X: 1704755501.031365,
  //           Y: 1
  //         }
  //       ],
  //       hitPost: true
  //     }
  //   }
  //   // '1686039583123748047': {
  //   //   id: '1686039583123748047',
  //   //   eventId: '1686039583116374338',
  //   //   event: {
  //   //     id: '1686039583116374338',
  //   //     sessionId: '1686039090457917025',
  //   //     startTime: 1686039583.1163993,
  //   //     timeEnd: 0,
  //   //     type: 6
  //   //   },
  //   //   type: 'TRY',
  //   //   subType: 'UNCLASSIFIED',
  //   //   confirmed: true,
  //   //   ignore: false,
  //   //   positionX: 55.0013951741053261912,
  //   //   positionY: 10.4824121003114121,
  //   //   positionZ: 0.01618174252629867
  //   // }
  // },
  flights: {},
  columns: ['dist', 'hangTime'],
  flightGraphs: {},
  sessionId: null,
  penaltyCountdownActive: false,
  stoppageEventLoading: false
}

// TODO: this need fixing - this function detects when the event is a flight and adds the event object to it - this object is present in other event types - it should be present in flights
// It also adds the UI event type value to the flight object - enum > string
function updateFlightType(flight) {
  const eventType = getOldEventType(flight.type)
  if (eventType && flight.dist !== undefined) {
    flight.type = eventType.value
    flight.event = {
      type: eventTypes.items.flight.value,
      id: flight.id,
      sessionId: flight.sessionId,
      startTime: flight.startTime,
      endTime: flight.timeEnd
    }
  }
  return flight
}

function updateFlightTypes(data) {
  return data.map(updateFlightType)
}

function updateTimeEvent(event) {
  // Hacky way to tell if it's a time event
  if (!event.event && !event.dist) {
    event.event = {
      type: eventTypes.items.time.value,
      id: event.id,
      sessionId: event.sessionId,
      startTime: event.startTime,
      endTime: event.timeEnd
    }
  }
  return event
}

function updateTimeEvents(data) {
  return data.map(updateTimeEvent)
}

export function eventsReducer(state = initialState, action) {
  let data
  let tableData
  let formattedEvent
  let tableEvent
  let updatedEvent
  switch (action.type) {
    case GET_FLIGHT: {
      let updatedFlight = updateFlightType(action.payload.event)
      updatedFlight = updateFlightWithBouncedToTouch(updatedFlight)

      const formattedEvent = formatEventData(
        updatedFlight,
        action.payload.formattedSession,
        SI
      )

      const tableEvent = getTableEvent(formattedEvent)

      // in here update items as well
      return {
        ...state,
        rawData: {
          ...state.rawData,
          [updatedFlight.id]: updatedFlight
        },
        tableData: state.tableData.map((event) =>
          event.id === action.payload.event.id ? tableEvent : event
        )
      }
    }
    case GET_EVENT:
      updatedEvent = updateFlightType(action.payload.event)
      updatedEvent = updateFlightWithBouncedToTouch(updatedEvent)

      formattedEvent = formatEventData(
        updatedEvent,
        action.payload.formattedSession,
        SI
      )

      tableEvent = getTableEvent(formattedEvent)

      return {
        ...state,
        rawData: {
          ...state.rawData,
          [action.payload.event.id]: updatedEvent
        },
        tableData: state.tableData.map((event) =>
          event.id === action.payload.event.id ? tableEvent : event
        )
      }
    case GET_SESSION_EVENTS:
      data = action.payload.data
      data = updateFlightTypes(data)
      data = updateTimeEvents(data)
      data = _.keyBy(data, 'id')

      tableData = []

      for (const key in data) {
        const updatedEvent = updateFlightWithBouncedToTouch(data[key])

        data[key] = updatedEvent

        const formattedEvent = formatEventData(
          updatedEvent,
          action.payload.formattedSession,
          SI
        )

        const tableEvent = getTableEvent(formattedEvent)

        tableData.push(tableEvent)
      }

      return {
        ...state,
        rawData: data,
        sessionId: action.payload.sessionId,
        tableData: tableData
      }

    case ADD_EVENT:
      data = updateFlightType({ ...action.payload.event })
      data = updateTimeEvent(data)

      // TODO: session id needs to be added to game event //
      // Add session id to game event - temp fix for AFL //
      if (data.event && !data.event.sessionId) {
        data.event.sessionId = action.payload.formattedSession.id
      }
      if (!data.sessionId) data.sessionId = action.payload.formattedSession.id

      formattedEvent = formatEventData(
        data,
        action.payload.formattedSession,
        SI
      )

      tableEvent = getTableEvent(formattedEvent)

      return {
        ...state,
        rawData: {
          ...state.rawData,
          [action.payload.event.id]: data
        },
        tableData: [...state.tableData, tableEvent]
      }

    case SELECT_EVENT:
      return {
        ...state,
        selectedEventId: action.payload
      }

    case UPDATE_EVENT:
      data = updateFlightType({ ...action.payload.event })

      formattedEvent = formatEventData(
        data,
        action.payload.formattedSession,
        SI
      )

      tableEvent = getTableEvent(formattedEvent)

      return {
        ...state,
        rawData: {
          ...state.rawData,
          [action.payload.event.id]: {
            ...state.rawData[action.payload.id],
            ...data
          }
        },
        tableData: state.tableData.map((event) =>
          event.id === action.payload.event.id ? tableEvent : event
        )
      }

    case RESET_SELECTED_EVENT:
      return {
        ...state,
        selectedEventId: undefined
      }

    case UPDATE_SELECTED_COLUMNS:
      return {
        ...state,
        columns: action.payload
      }

    case SET_SESSION: {
      let columns =
        action.payload.type === sessionTypesConfig.match.value
          ? ['inPitchDistance', 'territorialGain']
          : action.payload.type === sessionTypesConfig.training.value
          ? ['inPitchDistance', 'inPitchHangTime']
          : ['dist', 'hangTime']
      if (action.payload.sportType === sportTypes.items.soccer.value)
        columns = ['dist', 'hangTime']
      return {
        ...state,
        columns: columns
      }
    }

    case SET_PENALTY_COUNTDOWN_ACTIVE:
      return {
        ...state,
        penaltyCountdownActive: action.payload
      }

    case CHANGE_EVENT_FILTER_TYPE:
      return {
        ...state,
        selectedEventFilterType: action.payload
      }

    case SET_STOPPAGE_LOADING:
      return {
        ...state,
        stoppageEventLoading: action.payload.data
      }

    case SET_SESSION_TIME_EVENTS: {
      data = action.payload.data
      data = _.keyBy(data, 'id')
      const deletedId = action.payload.deletedId
      const rawData = { ...state.rawData }
      if (rawData[deletedId]) delete rawData[deletedId]

      for (const key in data) {
        const timeEvent = data[key]
        updateTimeEvent(timeEvent)
      }

      return {
        ...state,
        rawData: {
          ...rawData,
          ...data
        }
      }
    }

    case SET_SESSION_GAME_EVENTS:
      data = action.payload.data
      data = _.keyBy(data, 'id')

      tableData = []

      for (const key in data) {
        const gameEvent = data[key]
        const formattedEvent = formatEventData(
          gameEvent,
          action.payload.formattedSession,
          SI
        )

        const tableEvent = getTableEvent(formattedEvent)

        tableData.push(tableEvent)
      }

      return {
        ...state,
        rawData: {
          ...state.rawData,
          ...data
        }
      }

    case 'UPDATE_BROADCAST_STATE_TIME_EVENTS':
      for (const key in action.payload.timeEvents) {
        const timeEvent = action.payload.timeEvents[key]
        updateTimeEvent(timeEvent)
      }

      return {
        ...state,
        rawData: {
          ...state.rawData,
          ...data
        }
      }

    case CLEAR_SESSION_EVENTS:
      return {
        ...state,
        rawData: {},
        events: {},
        gameEvents: {},
        flights: {},
        aussieRulesEvents: {},
        tableData: []
      }
    case GET_LINE_GRAPH_DATA:
      return {
        ...state,
        flightGraphs: {
          ...state.flightGraphs,
          ...action.payload
        }
      }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
