import React, { useMemo, useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  ReferenceArea
} from 'recharts'
import { useEvents, useFormattedEvent } from '../hooks'
import moment from 'moment'
import Checkbox from '../../../components/Forms/Inputs/Checkbox/Checkbox'
import { useAppDispatch } from '../../../store/hooks'
import { getLineChartData } from '../actions'
import { eventTypes } from '../data_types'

export type EventChartProps = {
  eventId: string
}

export const EventChart = ({ eventId }: EventChartProps) => {
  const dispatch = useAppDispatch()

  const events = useEvents()

  const formattedEvent = useFormattedEvent(eventId)

  // Set data to the flightGraph data if it exists, otherwise fetch it //
  const data = useMemo(() => {
    if (
      !formattedEvent ||
      formattedEvent.eventType !== eventTypes.items.flight.value
    )
      return
    const flightGraph = events.flightGraphs[eventId]
    if (flightGraph) return flightGraph.data
    dispatch(getLineChartData(eventId))
  }, [formattedEvent, events.flightGraphs])

  const [showAccAndGyro, setShowAccAndGyro] = useState(true)

  // Tooltip formatter for value and label
  const formatTooltipValue = (value: number, name: string, entry: any) => {
    if (!showAccAndGyro) return [null, null]
    return [value.toFixed(2), name] // Keep value and name as is, modify if needed
  }

  const formatTooltipLabel = (label: number) => {
    const date = moment.unix(label)
    const formattedTime = date.format('HH:mm:ss:SSS')
    return formattedTime // Format the label (time) using the formatTime function
  }

  const fontSize = 12

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0
        }}
      >
        <Checkbox
          onClicked={(value) => setShowAccAndGyro((prev) => value)}
          label={'Show Acc and Gyro'}
          checked={showAccAndGyro}
        />
      </div>

      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          width={500}
          height={300}
          data={data?.imuData || []}
          margin={{
            top: 20,
            right: 50,
            left: 0,
            bottom: 5
          }}
        >
          <XAxis
            dataKey='time'
            tickFormatter={(value) => {
              const date = moment.unix(value)
              const seconds = date.format('ss')
              const milliseconds = date.format('SSS')
              return `${seconds}.${milliseconds}`
            }}
            tick={{ fontSize }}
          />
          <YAxis tick={{ fontSize }} />
          <Tooltip
            formatter={formatTooltipValue} // Customizes the value display
            labelFormatter={formatTooltipLabel} // Customizes the label (timestamp) display
          />
          {/* <Legend /> */}
          <ReferenceLine
            x={formattedEvent.startTime}
            stroke='#708090'
            label={{
              value: 'Start',
              fontSize,
              position: 'left'
            }}
          />
          <ReferenceArea
            x1={formattedEvent.startTime}
            x2={formattedEvent.endTime}
            fillOpacity={0.1}
            fill='#708090'
          />
          {data &&
            data.events
              .filter((event) => event.type === 'IMPACT')
              .map((event) => (
                <ReferenceLine
                  x={event.event?.startTime}
                  stroke='#DC143C'
                  label={{ value: 'Impact', fontSize, position: 'top' }}
                  isFront={true}
                  alwaysShow={true}
                />
              ))}
          <ReferenceLine
            x={formattedEvent.endTime}
            stroke='#708090'
            label={{ value: 'End', fontSize, position: 'right' }}
          />
          <Line
            dot={false}
            type='monotone'
            dataKey='acceleration'
            stroke='#50C878'
            hide={!showAccAndGyro}
          />
          <Line
            dot={false}
            type='monotone'
            dataKey='gyro'
            stroke='#001F54'
            hide={!showAccAndGyro}
          />
          <Line
            dot={false}
            type='monotone'
            dataKey='acceleration'
            stroke='transparent'
            hide={showAccAndGyro}
          />
          <Line
            dot={false}
            type='monotone'
            dataKey='gyro'
            stroke='transparent'
            hide={showAccAndGyro}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}
