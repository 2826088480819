import React from 'react'
import { FormattedDevice } from '../../../types'
import { isDeviceType } from '../../../data_types'
import { HardwareCommon } from './HardwareCommon/HardwareCommon'
import { HardwareBall } from './HardwareBall/HardwareBall'
import { HardwareTag } from './HardwareTag/HardwareTag'

export interface HardwareItemProps {
  device: FormattedDevice
  isSessionAssignment: boolean
}

export const HardwareItem = (props: HardwareItemProps) => {
  const { device, isSessionAssignment } = props

  const getHardwareComponent = (device: FormattedDevice) => {
    if (isDeviceType.ball(device.type)) {
      return (
        <HardwareBall
          device={device}
          isSessionAssignment={isSessionAssignment}
        />
      )
    } else if (isDeviceType.playerTag(device.type)) {
      return (
        <HardwareTag
          device={device}
          isSessionAssignment={isSessionAssignment}
        />
      )
    } else if (isDeviceType.anchor(device.type)) {
      return <HardwareCommon device={device} />
    } else {
      return <noscript />
    }
  }

  return (
    <div className='relative'>
      {getHardwareComponent(device)}
      {device.inSession && (
        <div
          className='w-3 h-3 absolute -right-2 -top-2 rounded-full flex justify-center items-center'
          style={{
            backgroundColor: 'white',
            border: `2px solid black`,
            fontSize: '0.6rem',
            fontWeight: 'bold',
            lineHeight: '1.2rem',
            zIndex: 10
          }}
        >
          <div className='mt-0'>S</div>
        </div>
      )}
    </div>
  )
}
