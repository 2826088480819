import pitchTexture from '../../../assets/img/pitch-texture.png'

import { calculateOffsetAndScale } from '../field'

import { draw2DCircle } from './drawing'

import { drawRugbyLines } from '../sports/rugby'
import { drawRugbyLeagueLines } from '../sports/rugby_league'
import {
  drawAmericanFootballLines,
  drawAmericanFootballPitchNumbers
} from '../sports/american_football'
import {
  drawCanadianFootballLines,
  drawCanadianFootballPitchNumbers
} from '../sports/canadian_football'
import { drawAustralianRulesLines } from '../sports/australian_rules'

import { drawDemonstrationLines } from '../sports/demonstration'
import { sportTypes } from '../../../metrics_server/sports/data_types'
import { drawSoccerLines } from '../sports/soccer'
import { Drill } from '../../../metrics_server/drills/types'

export function load2D(props) {
  const {
    field,
    canvasId2D,
    coverId,
    canvasSectionWidth,
    canvasHeight,
    anchorSetup,
    canvasContainer,
    pitchType,
    getCanvasCoordinate,
    fieldWidth,
    fieldHeight,
    clearMapFrame,
    teams,
    dimensions
  } = props

  props.twoDCanvas = document.getElementById(canvasId2D)
  props.twoDCanvas.style.boxShadow = 'inset 0px 0px 66px rgba(0,0,0,0.5)'
  props.coverCanvas = document.getElementById(coverId)

  const { twoDCanvas, coverCanvas } = props

  // Set offset based on pitch type

  const sport = sportTypes.getTypeByValue(pitchType)

  let fieldScreenWidth = field.width
  let fieldScreenHeight = field.height

  if (props.pitchRotated && sport.props.pitch.enableRotate) {
    fieldScreenWidth = field.height
    fieldScreenHeight = field.width
  }

  const os = calculateOffsetAndScale(
    field,
    canvasSectionWidth,
    canvasHeight,
    fieldScreenWidth,
    fieldScreenHeight,
    anchorSetup,
    props.minPadding2D || sport.props.pitch.minPadding2D
  )
  props.twoDOffsetX = os.offsetX
  props.twoDOffsetY = os.offsetY
  props.scale = os.scale

  const { twoDOffsetX, twoDOffsetY, scale } = props
  // TODO apply * props.canvasElementPixelRatio
  props.canvas2DPixelScale = scale
  canvasContainer.style.width = `${
    (fieldScreenWidth + twoDOffsetX * 2) * scale
  }px`

  props.mapCtx = twoDCanvas.getContext('2d')
  props.coverCtx = coverCanvas.getContext('2d')

  const { canvas2DPixelScale, coverCtx, session } = props

  // Set scaled dimensions and position of canvas and cover

  twoDCanvas.style.width = `${(fieldScreenWidth + twoDOffsetX * 2) * scale}px`
  twoDCanvas.style.height = `${(fieldScreenHeight + twoDOffsetY * 2) * scale}px`
  twoDCanvas.width = (fieldScreenWidth + twoDOffsetX * 2) * canvas2DPixelScale
  twoDCanvas.height = (fieldScreenHeight + twoDOffsetY * 2) * canvas2DPixelScale

  coverCanvas.style.top = `0px`
  coverCanvas.style.left = `0px`
  coverCanvas.style.backgroundPosition = 'center'
  coverCanvas.style.width = `${(fieldScreenWidth + twoDOffsetX * 2) * scale}px`
  coverCanvas.style.height = `${
    (fieldScreenHeight + twoDOffsetY * 2) * scale
  }px`
  coverCanvas.width = twoDCanvas.width
  coverCanvas.height = twoDCanvas.height

  const backgroundImg = new Image()
  const centerPitchLogo = new Image()

  clearMapFrame(coverCtx)

  // Generate canvas coordinates for top, bottom, left and right planes of the pitch
  props.pitchPlaneCanvasCoordinates = {
    left: getCanvasCoordinate(canvas2DPixelScale, field.planes.left, null)
      .scaleX,
    right: getCanvasCoordinate(canvas2DPixelScale, field.planes.right, null)
      .scaleX,
    top: getCanvasCoordinate(canvas2DPixelScale, null, field.planes.top).scaleY,
    bottom: getCanvasCoordinate(canvas2DPixelScale, null, field.planes.bottom)
      .scaleY
  }

  // New sport needs to be added here
  switch (pitchType) {
    case sportTypes.items.demonstration.value:
      coverCanvas.style.background = field.color
      backgroundImg.src = pitchTexture
      drawDemonstrationLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale
      )
      break
    case sportTypes.items.rugbyUnion.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      backgroundImg.src = pitchTexture
      drawRugbyLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale
      )
      // only draw center logo when session is live (not in setup)
      if (!anchorSetup.active && !session.isMatchMode) {
        centerPitchLogo.onload = () => {
          coverCtx.save()
          coverCtx.globalAlpha = 0.7
          coverCtx.drawImage(
            centerPitchLogo,
            getCanvasCoordinate(canvas2DPixelScale, 0).scaleX - 50 / 2,
            getCanvasCoordinate(canvas2DPixelScale, null, field.height / 2)
              .scaleY -
              50 / 2,
            50,
            50
          )
          coverCtx.restore()
        }
      }
      centerPitchLogo.src = teams.A.logo
      break
    case sportTypes.items.rugbyLeague.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      drawRugbyLeagueLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale
      )
      break
    case sportTypes.items.americanFootball.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      drawAmericanFootballLines(
        props,
        'rgba(255,255,255,1)',
        3,
        coverCtx,
        canvas2DPixelScale,
        null
      )
      drawAmericanFootballPitchNumbers(
        field,
        dimensions,
        getCanvasCoordinate,
        canvas2DPixelScale,
        coverCtx,
        canvasHeight * 0.02453271028
      )
      break
    // case sportTypes.items.boxing?.value:
    //   drawBoxingLines(props, 'rgba(255,255,255,0.9)')
    //   coverCanvas.style.background = field.color
    //   coverCanvas.style.backgroundImage = `url(${boxingTexture})`
    //   break
    case sportTypes.items.australianRules.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      backgroundImg.src = pitchTexture
      drawAustralianRulesLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale,
        twoDCanvas,
        null
      )
      break
    case sportTypes.items.canadianFootball.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      drawCanadianFootballLines(
        props,
        'rgba(255,255,255,1)',
        3,
        coverCtx,
        canvas2DPixelScale,
        null
      )
      // const { dimensions } = props
      drawCanadianFootballPitchNumbers(
        field,
        dimensions,
        getCanvasCoordinate,
        canvas2DPixelScale,
        coverCtx,
        canvasHeight * 0.02453271028
      )
      // only draw center logo when session is live (not in setup)
      if (!anchorSetup.active) {
        centerPitchLogo.onload = () => {
          coverCtx.save()
          coverCtx.globalAlpha = 0.7
          coverCtx.drawImage(
            centerPitchLogo,
            getCanvasCoordinate(canvas2DPixelScale, 0).scaleX - 50 / 2,
            getCanvasCoordinate(canvas2DPixelScale, null, field.height / 2)
              .scaleY -
              50 / 2,
            50,
            50
          )
          coverCtx.restore()
        }
      }
      centerPitchLogo.src = teams.A.logo
      break
    case sportTypes.items.soccer.value:
      coverCanvas.style.background = field.color
      coverCanvas.style.backgroundImage = `url(${pitchTexture})`
      drawSoccerLines(
        props,
        'rgba(255,255,255,0.9)',
        coverCtx,
        canvas2DPixelScale,
        null,
        props.pitchFlipped,
        props.pitchRotated
      )
      break
    default:
      break
  }

  // Create rugby players
  create2DTags(props)

  if (props.showBench) {
    generateBench(props, coverCtx)
  }

  // Draw axis
  if (anchorSetup.active) {
    const canvasOrigin = getCanvasCoordinate(canvas2DPixelScale, 0, 0)
    drawAxisArrows(
      coverCtx,
      { x: canvasOrigin.scaleX, y: canvasOrigin.scaleY },
      props.pitchFlipped,
      sport
    )
  }

  // // Draw person
  // let personImgWidth = 30
  // let personImg = new Image()
  // personImg.src = personImgSrc
  // personImg.onload = (event) => {
  //   if (coverCtx) {
  //     coverCtx.drawImage(
  //       event.target,
  //       getCanvasCoordinate(canvas2DPixelScale, 0).scaleX - personImgWidth / 2,
  //       getCanvasCoordinate(canvas2DPixelScale, null, 0).scaleY,
  //       personImgWidth,
  //       personImgWidth
  //     )
  //   }
  // }

  // Debug

  // coverCtx.save()

  // for (let i in dimensions) {
  //   let point = dimensions[i]
  //   let coordinate = getCanvasCoordinate(canvas2DPixelScale, point.x, point.y)
  //   coverCtx.textAlign = 'center'
  //   coverCtx.fillStyle = '#000000'
  //   coverCtx.strokeStyle = '#000000'
  //   coverCtx.font = '14px Mark Pro'
  //   let number = i.split('P')[1]
  //   coverCtx.fillText(number, coordinate.scaleX, coordinate.scaleY)
  //   coverCtx.fillText(number, coordinate.scaleX, coordinate.scaleY)
  // }
  // coverCtx.restore()
}

function drawAxisArrows(ctx, origin, invertAxes, sport) {
  if (sport.value === sportTypes.items.soccer.value) return

  ctx.strokeStyle = 'blue'
  ctx.lineWidth = 1

  const xSign = invertAxes ? -1 : 1
  const ySign = invertAxes ? 1 : -1

  // Draw x-axis
  ctx.beginPath()
  ctx.moveTo(origin.x, origin.y)
  ctx.lineTo(origin.x + 20 * xSign, origin.y)
  ctx.stroke()

  // Draw y-axis
  ctx.beginPath()
  ctx.moveTo(origin.x, origin.y + 20 * ySign)
  ctx.lineTo(origin.x, origin.y)
  ctx.stroke()
  // Draw x-axis arrow
  ctx.beginPath()
  ctx.moveTo(origin.x + 20 * xSign, origin.y)
  ctx.lineTo(origin.x + 20 * xSign - 4 * xSign, origin.y - 2)
  ctx.moveTo(origin.x + 20 * xSign, origin.y)
  ctx.lineTo(origin.x + 20 * xSign - 4 * xSign, origin.y + 2)
  ctx.stroke()

  // Draw y-axis arrow
  ctx.beginPath()
  ctx.moveTo(origin.x, origin.y + 20 * ySign)
  ctx.lineTo(origin.x - 2, origin.y + 20 * ySign - 4 * ySign)
  ctx.moveTo(origin.x, origin.y + 20 * ySign)
  ctx.lineTo(origin.x + 2, origin.y + 20 * ySign - 4 * ySign)
  ctx.stroke()

  // Draw labels
  ctx.font = '15px Arial'
  ctx.fillStyle = 'black'
  ctx.fillText('x', origin.x + 20 * xSign + 5, origin.y + 5)
  ctx.fillText('y', origin.x + 5, origin.y + 20 * ySign - 5)
}

export function create2DTags(props) {
  props.players2D = {}
  props.balls2D = {}
  props.tags2DSelected = {}

  const {
    player2DSize,
    ball2DSize,
    canvas2DPixelScale,
    canvasContainer2D,
    teams
  } = props
  // Selected Canvas
  const selectedBorderSize = 5
  const selectedDeviceCanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  canvasContainer2D.append(selectedDeviceCanvas)
  draw2DCircle(
    selectedDeviceCanvas.getContext('2d'),
    player2DSize / 2 + selectedBorderSize,
    player2DSize / 2 + selectedBorderSize,
    'yellow',
    'transparent',
    player2DSize / 2 + selectedBorderSize,
    2,
    [5, 5]
  )
  props.selectedBorderSize = 5
  props.selectedDevice2D = selectedDeviceCanvas
  // Low Power Mode
  const lowPowerModeCanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  canvasContainer2D.append(lowPowerModeCanvas)
  draw2DCircle(
    lowPowerModeCanvas.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'black',
    'transparent',
    player2DSize / 2,
    2
  )
  props.lowPowerMode2D = lowPowerModeCanvas
  // Unknown Power Mode
  const unknownPowerModeCanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  canvasContainer2D.append(unknownPowerModeCanvas)
  draw2DCircle(
    unknownPowerModeCanvas.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'red',
    'transparent',
    player2DSize / 2,
    2
  )
  props.unknownPowerMode2D = unknownPowerModeCanvas

  // Balls
  // Standard
  const ballCanvas = genCanvas(ball2DSize, ball2DSize, canvas2DPixelScale)
  canvasContainer2D.append(ballCanvas)
  draw2DCircle(
    ballCanvas.getContext('2d'),
    ball2DSize / 2,
    ball2DSize / 2,
    'transparent',
    '#E4F6F8',
    ball2DSize / 2,
    2
  )
  props.ball2D = ballCanvas
  // In Play
  const ballCanvasInPlay = genCanvas(ball2DSize, ball2DSize, canvas2DPixelScale)
  canvasContainer2D.append(ballCanvasInPlay)
  draw2DCircle(
    ballCanvasInPlay.getContext('2d'),
    ball2DSize / 2,
    ball2DSize / 2,
    'transparent',
    '#04bcf7',
    ball2DSize / 2,
    2
  )
  props.ball2DInPlay = ballCanvasInPlay

  // Match officials
  const officialCanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  canvasContainer2D.append(officialCanvas)
  draw2DCircle(
    officialCanvas.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'rgba(0,0,0,0.6)',
    ['black', 'white'],
    player2DSize / 2,
    2,
    []
  )
  props.officials2D = officialCanvas

  // Create Team A Canvas
  const playerACanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  canvasContainer2D.append(playerACanvas)
  draw2DCircle(
    playerACanvas.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'transparent',
    '#ffff33',
    player2DSize / 2,
    2
  )
  props.playerACanvas = playerACanvas
  // Create Team B Canvas
  const playerBCanvas = genCanvas(
    player2DSize,
    player2DSize,
    canvas2DPixelScale
  )
  canvasContainer2D.append(playerBCanvas)
  draw2DCircle(
    playerBCanvas.getContext('2d'),
    player2DSize / 2,
    player2DSize / 2,
    'transparent',
    '#ff9f21',
    player2DSize / 2,
    2
  )
  props.playerBCanvas = playerBCanvas

  // loop through drill bibs and create
  const drill = props.drill as Drill
  props.drillBibCanvases = {}

  if (drill) {
    for (const bibId in drill.extraInfo.bibs) {
      const bib = drill.extraInfo.bibs[bibId]
      const bibCanvas = genCanvas(
        player2DSize,
        player2DSize,
        canvas2DPixelScale
      )
      canvasContainer2D.append(bibCanvas)
      draw2DCircle(
        bibCanvas.getContext('2d'),
        player2DSize / 2,
        player2DSize / 2,
        'transparent',
        bib.colour ? bib.colour : '#ffff33',
        player2DSize / 2,
        2
      )
      props.drillBibCanvases[bibId] = bibCanvas
    }
  }
}

//--> Generate 2D Canvas
const genCanvas = (width, height, scale) => {
  const canvas = document.createElement('canvas')
  canvas.style.width = `${width}px`
  canvas.style.height = `${height}px`
  canvas.width = width * scale
  canvas.height = height * scale
  canvas.style.display = 'none'
  canvas.style.position = 'absolute'
  return canvas
}

export const appendTo2dCanvas = (
  canvas,
  className,
  canvasContainer2D,
  twoDCanvas,
  fieldWidth,
  fieldHeight,
  twoDOffsetX,
  twoDOffsetY,
  scale
) => {
  canvasContainer2D.append(canvas)

  canvas.style.width = `${(fieldWidth + twoDOffsetX * 2) * scale}px`
  canvas.style.height = `${(fieldHeight + twoDOffsetY * 2) * scale}px`
  canvas.width = twoDCanvas.width
  canvas.height = twoDCanvas.height
  canvas.style.position = 'absolute'
  canvas.className = className
}

function generateBench(props, ctx) {
  const { session, canvasSectionWidth, field, teams } = props

  if (!session || !session.teams || !session.playersSessions || !session.live)
    return

  const benchPositions = {}
  const benchHeight = 50
  const benchSlotSize = props.player2DSize + 3
  const benchPadding = 2
  const benchRightLeftPadding = 80

  // Draw background for the first bench
  ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'
  ctx.fillRect(
    0,
    props.canvasHeight - benchHeight,
    canvasSectionWidth,
    benchHeight
  )
  // Draw background for the second bench
  ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'
  ctx.fillRect(0, 0, canvasSectionWidth, benchHeight)
  const teamA = teams.A
  session.teams.list.forEach((team, index) => {
    benchPositions[team.id] = {
      number: [],
      serial: []
    }
    session.playersSessions.byHardwareId.list.forEach((playerSession) => {
      if (playerSession.playerId && playerSession.teamId == team.id) {
        benchPositions[team.id].number.push({
          hardwareId: playerSession.hardwareId,
          number: playerSession.number
        })
        benchPositions[team.id].serial.push({
          hardwareId: playerSession.hardwareId,
          serial: playerSession.hardware.serial
        })
      }
    })
    // Sort bench positions by number
    benchPositions[team.id].number.sort((a, b) => {
      return a.number - b.number
    })

    // Sort bench positions by last two characters of serial
    benchPositions[team.id].serial.sort((a, b) => {
      return a.serial.slice(-2) - b.serial.slice(-2)
    })

    const y =
      index === 0 ? props.canvasHeight - benchHeight / 2 : 0 + benchHeight / 2

    // Set bench positions based on pitch dimensions
    benchPositions[team.id].number.forEach((benchPosition, index) => {
      const teamASlotsXpos =
        canvasSectionWidth -
        benchRightLeftPadding -
        index * (benchSlotSize + benchPadding)
      const teamBSlotsXpos =
        benchRightLeftPadding + index * (benchSlotSize + benchPadding)

      const canvasPosition = {
        x: team.id === teamA.id ? teamBSlotsXpos : teamASlotsXpos,
        y: y
      }

      benchPosition.x = canvasPosition.x
      benchPosition.y = canvasPosition.y
    })

    // Set bench positions based on pitch dimensions
    benchPositions[team.id].serial.forEach((benchPosition, index) => {
      const teamASlotsXpos =
        canvasSectionWidth -
        benchRightLeftPadding -
        index * (benchSlotSize + benchPadding)
      const teamBSlotsXpos =
        benchRightLeftPadding + index * (benchSlotSize + benchPadding)

      const canvasPosition = {
        x: team.id === teamA.id ? teamBSlotsXpos : teamASlotsXpos,
        y: y
      }

      benchPosition.x = canvasPosition.x
      benchPosition.y = canvasPosition.y
    })
  })

  console.log('Bench positions', benchPositions)

  props.benchPositions = benchPositions

  // Draw bench circles
  for (const team in benchPositions) {
    benchPositions[team].number.forEach((benchPosition) => {
      draw2DCircle(
        ctx,
        benchPosition.x,
        benchPosition.y,
        'rgba(255,255,255,0.9)',
        'transparent',
        benchSlotSize / 2,
        2
      )
    })
  }
  // Draw Label for both benches
  ctx.save()
  ctx.fillStyle = '#fff'
  ctx.font = '12px sans-serif'
  // Measure the width of the text
  const text = 'Low Power Devices'
  const textWidth = ctx.measureText(text).width

  // ctx.fillText(
  //   text,
  //   canvasSectionWidth - benchRightLeftPadding - textWidth,
  //   canvasHeight - benchTopBottomPadding
  // )
  // ctx.fillText('Low Power Device', benchRightLeftPadding, benchTopBottomPadding)
  ctx.restore()
}

export function getBenchPositionFromTagId(props, tagId) {
  const { benchPositions, showTagIds } = props
  const benchType = showTagIds ? 'serial' : 'number'
  for (const team in benchPositions) {
    const bench = benchPositions[team][benchType]
    for (const i in bench) {
      if (bench[i].hardwareId === tagId) {
        return bench[i]
      }
    }
  }
  return null
}
