import { QAReports } from '../../../../types'
import { QaToolTip } from './QaToolTip'
import { QAWarningUnknownIcon } from '../../../../../../components/Icons/QAWarningUnknownIcon/QAWarningUnknownIcon'
import { sportableColors } from '../../../../../../const'
import { QAWarningIcon } from '../../../../../../components/Icons/QAWarningIcon/QAWarningIcon'
import styles from './QaInfo.module.scss'

export const QAInfo = ({ qaReports }: { qaReports: QAReports }) => {
  if (!qaReports) {
    return (
      <div className={styles.qaWarning}>
        <QaToolTip
          icon={
            <QAWarningUnknownIcon
              scale={0.7}
              color={sportableColors.colors.colorError}
            />
          }
          unknownQA={true}
          colorError={sportableColors.colors.colorError}
        />
      </div>
    )
  }

  return (
    <div className={styles.qaWarning}>
      <QaToolTip
        icon={
          <QAWarningIcon
            scale={0.7}
            color={
              qaReports.investigate.passed ||
              qaReports.retired.passed ||
              (!qaReports.fieldQA.passed && !qaReports.hwQA.passed)
                ? sportableColors.colors.colorError
                : sportableColors.colors.colorWarning
            }
          />
        }
        qaReports={qaReports}
        colorError={sportableColors.colors.colorError}
        colorSuccess={sportableColors.colors.colorSuccess}
      />
    </div>
  )
}
