import React from 'react'
import { BatteryIcon } from '../../../Icons/BatteryIcon/BatteryIcon'
import styles from './StrackAnchor.module.scss'
import { FormattedDevice } from '../../../../metrics_server/hardware/types'

export interface StrackAnchorProps {
  side: string | 'top' | 'bottom'
  anchor?: FormattedDevice
}

export const StrackAnchor = ({ side, anchor }: StrackAnchorProps) => {
  return (
    <div
      data-testid='strack-anchor-container'
      style={{ [side]: '10px', position: 'absolute' }}
    >
      <div className={styles.container}>
        <div className={styles.batteryContainer}>
          <BatteryIcon device={anchor} isAnchor={false} />
        </div>
        <p
          className={styles.anchorId}
          style={{ color: anchor.hasFluctuated ? 'red' : 'yellow' }}
        >
          {anchor.serial}
        </p>
      </div>
    </div>
  )
}
